import { createRouter, createWebHistory } from 'vue-router';
import AppLayout from '@/layout/AppLayout.vue';
import { useAuthStore } from '@/stores/authStore';
import path from 'path';

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            component: AppLayout,
            children: [
                {
                    path: '/',
                    name: 'e-commerce',
                    meta: {
                        breadcrumb: [{name: 'Dashboard', path: '/'}],
                        requiresAuth: true,
                        requiresAdmin: true
                    },
                    component: () => import('@/views/dashboards/Ecommerce.vue'),
                },
                {
                    path: '/customers',
                    name: 'customers',
                    meta: {
                        breadcrumb: [{name: 'Customers', path: '/customers'}],
                        requiresAuth: true,
                        requiresAdmin: true
                    },
                    component: () => import('@/views/customers/Customers.vue'),
                },
                {
                    path: '/customers/details/:customerNumber',
                    name: 'customer-details',
                    meta: {
                        breadcrumb:(route: { params: { customerNumber: string } }) => [{name: 'Customers', path: '/customers'}, {name: `Customer #${route.params.customerNumber}`, path: `/customers/details/${route.params.customerNumber}`}],
                        requiresAuth: true,
                        requiresAdmin: true
                    },
                    component: () => import('@/views/customers/CustomerDetails.vue'),
                },
                {
                    path: '/products',
                    name: 'products',
                    meta: {
                        breadcrumb: [{name:'Products', path: '/products'}],
                        requiresAuth: true,
                        requiresAdmin: true
                    },
                    component: () => import('@/views/products/Products.vue'),
                },
                {
                    path: '/carts',
                    name: 'carts',
                    meta: {
                        breadcrumb: [{name: 'Carts', path: '/carts'}],
                        requiresAuth: true,
                        requiresAdmin: true
                    },
                    component: () => import('@/views/carts/PendingOrders.vue'),
                },
                {
                    path: '/carts/:customerNumber',
                    name: 'cart-details',
                    meta: {
                        breadcrumb: (route: { params: { customerNumber: string } }) => [{name:'Carts', path: '/carts'},{name: `Customer #: ${route.params.customerNumber}`, path: `/carts/${route.params.customerNumber}`}],
                        requiresAuth: true,
                        requiresAdmin: true
                    },
                    component: () => import('@/views/carts/CartDetails.vue'), 
                },

                {
                    path: '/orders',
                    name: 'orders',
                    meta: {
                        breadcrumb: [{name: 'Orders', path: '/orders'}],
                        requiresAuth: true,
                        requiresAdmin: true
                    },
                    component: () => import('@/views/orders/Orders.vue'),
                },
                {
                    path: '/orders/:orderId',
                    name: 'order-details',
                    meta: {
                        breadcrumb: (route: { params: { orderId: string } }) => [{name: 'Orders', path: '/orders'}, {name:`Order ID: ${route.params.orderId}`, path: `/orders/${route.params.orderId}`}],
                        requiresAuth: true,
                        requiresAdmin: true
                    },
                    component: () => import('@/views/orders/Invoice.vue'),
                },
                {
                    path: '/sales-reps',
                    name: 'sales-reps',
                    meta: {
                        breadcrumb: [{name:'Sales Reps', path: '/sales-reps'}],
                        requiresAuth: true,
                        requiresAdmin: true
                    },
                    component: () => import('@/views/sales-reps/SalesReps.vue'),
                },
                {
                    path: '/access-requests',
                    name: 'access-requests',
                    meta: {
                        breadcrumb: [{name: 'Access Requests', path: '/access-requests'}],
                        requiresAuth: true,
                        requiresAdmin: true
                    },
                    component: () => import('@/views/access-requests/AccessRequests.vue'),
                },
                {
                    path: '/access-requests/details/:accessRequestId',
                    name: 'access-request-details',
                    meta: {
                        breadcrumb: (route: { params: { accessRequestId: string } }) => [{name: 'Access Requests', path: '/access-requests'}, {name: 'Details', path: `/access-requests/details/${route.params.accessRequestId}`}],
                        requiresAuth: true,
                        requiresAdmin: true
                    },
                    component: () => import('@/views/access-requests/AccessRequestDetails.vue'),
                },
                {
                    path: '/api-access',
                    name: 'api-access',
                    meta: {
                        breadcrumb: [{name: 'API Access', path: '/api-access'}],
                        requiresAuth: true,
                        requiresAdmin: true
                    },
                    component: () => import('@/views/api-access/ApiAccess.vue'),
                },
                {
                    path: '/quickbooks',
                    name: 'quickbooks',
                    meta: {
                        breadcrumb: [{name: 'Quickbooks', path: '/quickbooks'}],
                        requiresAuth: true,
                        requiresAdmin: true
                    },
                    component: () => import('@/views/quickbooks/Quickbooks.vue'),
                },
                {
                    path: '/quickbooks/callback',
                    name: 'quickbooks-callback',
                    meta: {
                        breadcrumb: [{name: 'Quickbooks', path: '/quickbooks-callback'}],
                        requiresAuth: true,
                        requiresAdmin: true
                    },
                    component: () => import('@/views/quickbooks/QuickbooksCallback.vue'),
                },
                {
                    path: 'data',
                    name: 'data',
                    meta: {
                        breadcrumb: [{name: 'Manage Data', path: '/data'}],
                        requiresAuth: true,
                        requiresAdmin: true
                    },
                    component: () => import('@/views/data/ImportData.vue'),
                    beforeEnter: (to, _, next ) => {
                        if (
                            Object.keys(to.query).length
                            && !!to.query.import
                        ){
                            return next()
                        }
                        return next('/data?import=Customers');
                      },
                },
                {
                    path: '/images',
                    name: 'images',
                    meta: {
                        breadcrumb: [{name: 'Images', path: '/images'}],
                        requiresAuth: true,
                        requiresAdmin: true
                    },
                    component: () => import('@/views/images/Images.vue')
                },
                {
                    path: '/pages/empty',
                    name: 'empty',
                    component: () => import('@/views/pages/Empty.vue')
                },
            ]
        },
        {
            path: '/home',
            name: 'home',
            component: () => import('@/views/pages/Landing.vue')
        },
        {
            path: '/sign-up',
            name: 'sign-up',
            component: () => import('@/views/pages/Sign-Up.vue')
        },
        {
            path: '/pages/notfound',
            name: 'notfound',
            component: () => import('@/views/pages/NotFound.vue')
        },
        {
            path: '/auth/callback',
            name: 'auth-callback',
            component: () => import('@/views/pages/auth/AuthCallback.vue')
        },
        {
            path: '/auth/login',
            name: 'login',
            component: () => import('@/views/pages/auth/Login.vue')
        },
        {
            path: '/auth/access',
            name: 'accessDenied',
            component: () => import('@/views/pages/auth/Access.vue')
        },
        {
            path: '/auth/download-app',
            name: 'downloadApp',
            component: () => import('@/views/pages/auth/DownloadApp.vue')
        },
        {
            path: '/auth/register',
            name: 'register',
            component: () => import('@/views/pages/auth/Register.vue')
        },
        {
            path: '/auth/forgotpassword',
            name: 'forgotpassword',
            component: () => import('@/views/pages/auth/ForgotPassword.vue')
        },
        {
            path: '/auth/newpassword',
            name: 'newpassword',
            component: () => import('@/views/pages/auth/NewPassword.vue')
        },
        {
            path: '/auth/verification',
            name: 'verification',
            component: () => import('@/views/pages/auth/Verification.vue')
        },
        {
            path: '/auth/lockscreen',
            name: 'lockscreen',
            component: () => import('@/views/pages/auth/LockScreen.vue')
        },
        {
            path: '/:pathMatch(.*)*',
            name: 'notfound',
            component: () => import('@/views/pages/NotFound.vue')
        }
    ],
    scrollBehavior() {
        return { left: 0, top: 0 };
    }
});

// Navigation Guard
router.beforeEach(async (to, from, next) => {
    const authStore = useAuthStore();
    await authStore.checkAuth();

    if (to.meta.requiresAuth && !authStore.isAuthenticated) {
        return next('/home');
    }
    if (to.meta.requiresAdmin && !authStore.admin) {
        return next('/auth/download-app');
    }
    next();
});

export default router;
