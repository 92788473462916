import { defineStore } from "pinia";
import { ref } from "vue";

export const useProfileStore = defineStore('profileStore', () => {

    //State
    const showProfileModal = ref(false)

    //Actions
    const toggleProfileModal = () => {
        showProfileModal.value = !showProfileModal.value;
    }
    const closeProfileModal = () => {
        showProfileModal.value = false;
    }
    const openProfileModal = () => {
        showProfileModal.value = true;
    }

    return {
        showProfileModal,
        toggleProfileModal,
        closeProfileModal,
        openProfileModal    
    };
});