/* tslint:disable */
/* eslint-disable */
// Generated by Microsoft Kiota
// @ts-ignore
import { EnrichCustomerTokenRequestBuilderRequestsMetadata, type EnrichCustomerTokenRequestBuilder } from './enrichCustomerToken/';
// @ts-ignore
import { type BaseRequestBuilder, type KeysToExcludeForNavigationMetadata, type NavigationMetadata } from '@microsoft/kiota-abstractions';

/**
 * Builds and executes requests for operations under /api/auth/access
 */
export interface AccessRequestBuilder extends BaseRequestBuilder<AccessRequestBuilder> {
    /**
     * The enrichCustomerToken property
     */
    get enrichCustomerToken(): EnrichCustomerTokenRequestBuilder;
}
/**
 * Uri template for the request builder.
 */
export const AccessRequestBuilderUriTemplate = "{+baseurl}/api/auth/access";
/**
 * Metadata for all the navigation properties in the request builder.
 */
export const AccessRequestBuilderNavigationMetadata: Record<Exclude<keyof AccessRequestBuilder, KeysToExcludeForNavigationMetadata>, NavigationMetadata> = {
    enrichCustomerToken: {
        requestsMetadata: EnrichCustomerTokenRequestBuilderRequestsMetadata,
    },
};
/* tslint:enable */
/* eslint-enable */
