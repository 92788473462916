/* tslint:disable */
/* eslint-disable */
// Generated by Microsoft Kiota
// @ts-ignore
import { createErrorResponseFromDiscriminatorValue, createInternalErrorResponseFromDiscriminatorValue, createShoppingCartFromDiscriminatorValue, serializeShoppingCart, serializeUpdateCartRequest, type ErrorResponse, type InternalErrorResponse, type ShoppingCart, type UpdateCartRequest } from '../../../../models/';
// @ts-ignore
import { CompleteCheckoutRequestBuilderRequestsMetadata, type CompleteCheckoutRequestBuilder } from './completeCheckout/';
// @ts-ignore
import { ItemsRequestBuilderNavigationMetadata, ItemsRequestBuilderRequestsMetadata, type ItemsRequestBuilder } from './items/';
// @ts-ignore
import { PoNumberRequestBuilderRequestsMetadata, type PoNumberRequestBuilder } from './poNumber/';
// @ts-ignore
import { ShippingRequestBuilderRequestsMetadata, type ShippingRequestBuilder } from './shipping/';
// @ts-ignore
import { type BaseRequestBuilder, type KeysToExcludeForNavigationMetadata, type NavigationMetadata, type Parsable, type ParsableFactory, type RequestConfiguration, type RequestInformation, type RequestsMetadata } from '@microsoft/kiota-abstractions';

/**
 * Builds and executes requests for operations under /api/carts/{customerNumber-id}/{shoppingCartId}
 */
export interface WithShoppingCartItemRequestBuilder extends BaseRequestBuilder<WithShoppingCartItemRequestBuilder> {
    /**
     * The completeCheckout property
     */
    get completeCheckout(): CompleteCheckoutRequestBuilder;
    /**
     * The items property
     */
    get items(): ItemsRequestBuilder;
    /**
     * The poNumber property
     */
    get poNumber(): PoNumberRequestBuilder;
    /**
     * The shipping property
     */
    get shipping(): ShippingRequestBuilder;
    /**
     * Update a shopping cart with new information
     * @param body Represents a request to update a shopping cart.
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {Promise<ShoppingCart>}
     * @throws {ErrorResponse} error when the service returns a 400 status code
     * @throws {InternalErrorResponse} error when the service returns a 500 status code
     */
     put(body: UpdateCartRequest, requestConfiguration?: RequestConfiguration<object> | undefined) : Promise<ShoppingCart | undefined>;
    /**
     * Update a shopping cart with new information
     * @param body Represents a request to update a shopping cart.
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {RequestInformation}
     */
     toPutRequestInformation(body: UpdateCartRequest, requestConfiguration?: RequestConfiguration<object> | undefined) : RequestInformation;
}
/**
 * Uri template for the request builder.
 */
export const WithShoppingCartItemRequestBuilderUriTemplate = "{+baseurl}/api/carts/{customerNumber%2Did}/{shoppingCartId}";
/**
 * Metadata for all the navigation properties in the request builder.
 */
export const WithShoppingCartItemRequestBuilderNavigationMetadata: Record<Exclude<keyof WithShoppingCartItemRequestBuilder, KeysToExcludeForNavigationMetadata>, NavigationMetadata> = {
    completeCheckout: {
        requestsMetadata: CompleteCheckoutRequestBuilderRequestsMetadata,
    },
    items: {
        requestsMetadata: ItemsRequestBuilderRequestsMetadata,
        navigationMetadata: ItemsRequestBuilderNavigationMetadata,
    },
    poNumber: {
        requestsMetadata: PoNumberRequestBuilderRequestsMetadata,
    },
    shipping: {
        requestsMetadata: ShippingRequestBuilderRequestsMetadata,
    },
};
/**
 * Metadata for all the requests in the request builder.
 */
export const WithShoppingCartItemRequestBuilderRequestsMetadata: RequestsMetadata = {
    put: {
        uriTemplate: WithShoppingCartItemRequestBuilderUriTemplate,
        responseBodyContentType: "application/json",
        errorMappings: {
            400: createErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
            500: createInternalErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
        },
        adapterMethodName: "send",
        responseBodyFactory:  createShoppingCartFromDiscriminatorValue,
        requestBodyContentType: "application/json",
        requestBodySerializer: serializeUpdateCartRequest,
        requestInformationContentSetMethod: "setContentFromParsable",
    },
};
/* tslint:enable */
/* eslint-enable */
