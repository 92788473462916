/* tslint:disable */
/* eslint-disable */
// Generated by Microsoft Kiota
// @ts-ignore
import { createErrorResponseFromDiscriminatorValue, createGetOrganizationResponseFromDiscriminatorValue, createInternalErrorResponseFromDiscriminatorValue, type ErrorResponse, type GetOrganizationResponse, type InternalErrorResponse } from '../../models/';
// @ts-ignore
import { ApiAccessRequestBuilderRequestsMetadata, type ApiAccessRequestBuilder } from './apiAccess/';
// @ts-ignore
import { type BaseRequestBuilder, type KeysToExcludeForNavigationMetadata, type NavigationMetadata, type Parsable, type ParsableFactory, type RequestConfiguration, type RequestInformation, type RequestsMetadata } from '@microsoft/kiota-abstractions';

/**
 * Builds and executes requests for operations under /api/organization
 */
export interface OrganizationRequestBuilder extends BaseRequestBuilder<OrganizationRequestBuilder> {
    /**
     * The apiAccess property
     */
    get apiAccess(): ApiAccessRequestBuilder;
    /**
     * Get Organization including Subscriptions and Entitlements
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {Promise<GetOrganizationResponse>}
     * @throws {ErrorResponse} error when the service returns a 400 status code
     * @throws {InternalErrorResponse} error when the service returns a 500 status code
     */
     get(requestConfiguration?: RequestConfiguration<object> | undefined) : Promise<GetOrganizationResponse | undefined>;
    /**
     * Get Organization including Subscriptions and Entitlements
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {RequestInformation}
     */
     toGetRequestInformation(requestConfiguration?: RequestConfiguration<object> | undefined) : RequestInformation;
}
/**
 * Uri template for the request builder.
 */
export const OrganizationRequestBuilderUriTemplate = "{+baseurl}/api/organization";
/**
 * Metadata for all the navigation properties in the request builder.
 */
export const OrganizationRequestBuilderNavigationMetadata: Record<Exclude<keyof OrganizationRequestBuilder, KeysToExcludeForNavigationMetadata>, NavigationMetadata> = {
    apiAccess: {
        requestsMetadata: ApiAccessRequestBuilderRequestsMetadata,
    },
};
/**
 * Metadata for all the requests in the request builder.
 */
export const OrganizationRequestBuilderRequestsMetadata: RequestsMetadata = {
    get: {
        uriTemplate: OrganizationRequestBuilderUriTemplate,
        responseBodyContentType: "application/json",
        errorMappings: {
            400: createErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
            500: createInternalErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
        },
        adapterMethodName: "send",
        responseBodyFactory:  createGetOrganizationResponseFromDiscriminatorValue,
    },
};
/* tslint:enable */
/* eslint-enable */
