<script setup lang="ts">
import { useLayout } from '@/layout/composables/layout';
import AppMenu from './AppMenu.vue';
import { useAuthStore } from '@/stores/authStore';
import OrgLogo from '@/components/sidebar/OrgLogo.vue';
import SidebarHeader from '@/components/sidebar/SidebarHeader.vue';
import SidebarAccountMenu from '@/components/sidebar/SidebarAccountMenu.vue';
const { layoutState } = useLayout();

const authStore = useAuthStore();

let timeout:any = null;

function onMouseEnter() {
    if (!layoutState.anchored) {
        if (timeout) {
            clearTimeout(timeout);
            timeout = null;
        }
        layoutState.sidebarActive = true;
    }
}

function onMouseLeave() {
    if (!layoutState.anchored) {
        if (!timeout) {
            timeout = setTimeout(() => (layoutState.sidebarActive = false), 300);
        }
    }
}

function onAnchorToggle() {
    layoutState.anchored = !layoutState.anchored;
}



</script>

<template>
    <div class="layout-sidebar" @mouseenter="onMouseEnter" @mouseleave="onMouseLeave">
        <div :class="$style.sidebarHeader" class="sidebar-header" >
            <!-- <OrgLogo /> -->
             <SidebarHeader />
        </div>
        <div class="layout-menu-container">
            <AppMenu />
        </div>
        <SidebarAccountMenu />
    </div>
</template>

<style module>

.sidebarHeader{
    @apply !pt-8 mt-1;
}
</style>
