<script setup lang="ts">
import { useLayout } from '@/layout/composables/layout';
import { calculateScrollbarWidth, getViewport } from '@primeuix/utils/dom';
import { it } from 'node:test';
import { nextTick, onBeforeMount, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

interface MenuItem {
    label: string;
    icon?: string;
    to?: string;
    url?: string;
    class?: string;
    target?: string;
    items?: MenuItem[];
    disabled?: boolean;
    visible?: boolean;
    command?: (args: { originalEvent: MouseEvent; item: MenuItem }) => void;
}

const route = useRoute();

const { layoutConfig, layoutState, setActiveMenuItem, toggleMenu, isHorizontal, isSlim, isSlimPlus, isDesktop } = useLayout();

const props = defineProps({
    item: {
        type: Object as () => MenuItem,
        default: () => ({})
    },
    index: {
        type: Number,
        default: 0
    },
    root: {
        type: Boolean,
        default: true
    },
    parentItemKey: {
        type: String,
        default: undefined
    }
});

const isActiveMenu = ref(false);
const itemKey = ref<string | undefined>(undefined);
const subMenuRef = ref<HTMLElement | null>(null);
const menuItemRef = ref<HTMLElement | null>(null);

onBeforeMount(() => {
    itemKey.value = props.parentItemKey ? props.parentItemKey + '-' + props.index : String(props.index);

    const activeItem = layoutState.activeMenuItem;

    const exactMatch = Boolean(activeItem === itemKey.value);
    const isStartsWith = Boolean(activeItem && typeof activeItem === 'string' && itemKey.value && activeItem.startsWith(itemKey.value + '-'));
    isActiveMenu.value = exactMatch || isStartsWith;
    handleRouteChange(route.path);
});

watch(
    () => isActiveMenu.value,
    () => {
        const rootIndex = props.root ? props.index : parseInt(`${props.parentItemKey}`[0], 10);
        const overlay = document.querySelectorAll<HTMLElement>('.layout-root-submenulist')[rootIndex];
        const target = document.querySelectorAll<HTMLElement>('.layout-root-menuitem')[rootIndex];

        if ((isSlim.value || isSlimPlus.value || isHorizontal.value) && isDesktop) {
            nextTick(() => {
                calculatePosition(overlay, target);
            });
        }
    }
);

watch(
    () => layoutState.activeMenuItem,
    (newVal) => {
        const exactMatch = Boolean(newVal === itemKey.value);
        const isStartsWith = Boolean(typeof newVal === 'string' && itemKey.value && newVal.startsWith(itemKey.value + '-'));
        isActiveMenu.value = exactMatch || isStartsWith;
    }
);

watch(
    () => layoutConfig.menuMode,
    () => {
        isActiveMenu.value = false;
    }
);

watch(
    () => layoutState.overlaySubmenuActive,
    (newValue) => {
        if (!newValue) {
            isActiveMenu.value = false;
        }
    }
);

watch(() => route.path, handleRouteChange);

watch(
    () => route.path,
    (newPath) => {
        if (!(isSlimPlus.value || isHorizontal.value) && props.item.to && props.item.to === newPath) {
            setActiveMenuItem(itemKey.value || null);
        } else if (isSlimPlus.value || isHorizontal.value) {
            isActiveMenu.value = false;
        }
    }
);

async function itemClick(event: MouseEvent, item: MenuItem) {
    if (item.disabled) {
        event.preventDefault();
        return;
    }

    if ((item.to || item.url) && (layoutState.staticMenuMobileActive || layoutState.overlayMenuActive)) {
        toggleMenu();
    }

    if (item.command) {
        item.command({ originalEvent: event, item: item });
    }

    if (item.items) {
        if (props.root && isActiveMenu.value && (isSlim.value || isSlimPlus.value || isHorizontal.value)) {
            layoutState.overlaySubmenuActive = false;
            layoutState.menuHoverActive = false;

            return;
        }

        setActiveMenuItem(isActiveMenu.value ? props.parentItemKey || null : itemKey.value || null);

        if (props.root && !isActiveMenu.value && (isSlim.value || isSlimPlus.value || isHorizontal.value)) {
            layoutState.overlaySubmenuActive = true;
            layoutState.menuHoverActive = true;
            isActiveMenu.value = true;

            removeAllTooltips();
        }
    } else {
        if (!isDesktop) {
            layoutState.staticMenuMobileActive = !layoutState.staticMenuMobileActive;
        }

        if (isSlim.value || isSlimPlus.value || isHorizontal.value) {
            layoutState.overlaySubmenuActive = false;
            layoutState.menuHoverActive = false;

            return;
        }

        setActiveMenuItem(itemKey.value || null);
    }
}

function handleRouteChange(newPath: string) {
    if (!(isSlim.value || isSlimPlus.value || isHorizontal.value) && props.item.to && props.item.to === newPath) {
        setActiveMenuItem(itemKey.value || null);
    } else if (isSlim.value || isSlimPlus.value || isHorizontal.value) {
        isActiveMenu.value = false;
    }
}

function onMouseEnter() {
    if (props.root && (isSlim.value || isSlimPlus.value || isHorizontal.value) && isDesktop) {
        if (!isActiveMenu.value && layoutState.menuHoverActive) {
            setActiveMenuItem(itemKey.value || null);
        }
    }
}

function removeAllTooltips() {
    const tooltips = document.querySelectorAll('.p-tooltip');
    tooltips.forEach((tooltip) => {
        tooltip.remove();
    });
}

function calculatePosition(overlay: HTMLElement, target: HTMLElement) {
    if (overlay) {
        const { left, top } = target.getBoundingClientRect();
        const { width: vWidth, height: vHeight } = getViewport();
        const [oWidth, oHeight] = [overlay.offsetWidth, overlay.offsetHeight];
        const scrollbarWidth = calculateScrollbarWidth();

        // reset
        overlay.style.top = overlay.style.left = '';

        if (isHorizontal.value) {
            const width = left + oWidth + scrollbarWidth;
            overlay.style.left = vWidth < width ? `${left - (width - vWidth)}px` : `${left}px`;
        } else if (isSlim.value || isSlimPlus.value) {
            const height = top + oHeight;
            overlay.style.top = vHeight < height ? `${top - (height - vHeight)}px` : `${top}px`;
        }
    }
}

function checkActiveRoute(item: MenuItem) {
    if (item.to && ['/access-requests', '/customers'].includes(item.to)) {
        return route.path.startsWith(item.to);
    }
    // Default behavior - exact match only
    return route.path === item.to;
}
</script>

<template>
    <li ref="menuItemRef" :class="{ 'layout-root-menuitem': root, 'active-menuitem': isActiveMenu }">
        <!-- Section Header - Do Not Delete - we may need this later -->
        <!-- <div v-if="root && item.visible !== false" class="layout-menuitem-root-text">{{ item.label }}</div> -->
        <a
            v-if="(!item.to || item.items) && item.visible !== false"
            :href="item.url"
            @click="itemClick($event, item)"
            :class="item.class"
            :target="item.target"
            tabindex="0"
            @mouseenter="onMouseEnter"
            v-tooltip.hover="isSlim && root && !isActiveMenu ? item.label : null"
        >
            <i :class="item.icon" class="layout-menuitem-icon"></i>
            <span class="layout-menuitem-text">{{ item.label }}</span>
            <i class="pi pi-fw pi-angle-down layout-submenu-toggler" v-if="item.items"></i>
        </a>
        <router-link
            v-if="item.to && !item.items && item.visible !== false"
            @click="itemClick($event, item)"
            :class="[item.class, { 'active-route': checkActiveRoute(item) }]"
            tabindex="0"
            :to="item.to"
            @mouseenter="onMouseEnter"
            v-tooltip.hover="(isSlim || isSlimPlus) && root && !isActiveMenu ? item.label : null"
        >
            <i :class="item.icon" class="layout-menuitem-icon"></i>
            <span class="layout-menuitem-text">{{ item.label }}</span>
            <i class="pi pi-fw pi-angle-down layout-submenu-toggler" v-if="item.items"></i>
        </router-link>

        <ul ref="subMenuRef" :class="{ 'layout-root-submenulist': root }" v-if="item.items && item.visible !== false">
            <AppMenuItem v-for="(child, i) in item.items" :key="i" :index="i" :item="child" :parentItemKey="itemKey" :root="false"></AppMenuItem>
        </ul>
    </li>
</template>
