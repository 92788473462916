/* tslint:disable */
/* eslint-disable */
// Generated by Microsoft Kiota
// @ts-ignore
import { type BaseRequestBuilder, type Parsable, type ParsableFactory, type RequestConfiguration, type RequestInformation, type RequestsMetadata } from '@microsoft/kiota-abstractions';

/**
 * Builds and executes requests for operations under /api/quickbooks/refresh
 */
export interface RefreshRequestBuilder extends BaseRequestBuilder<RefreshRequestBuilder> {
    /**
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     */
     get(requestConfiguration?: RequestConfiguration<object> | undefined) : Promise<void>;
    /**
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {RequestInformation}
     */
     toGetRequestInformation(requestConfiguration?: RequestConfiguration<object> | undefined) : RequestInformation;
}
/**
 * Uri template for the request builder.
 */
export const RefreshRequestBuilderUriTemplate = "{+baseurl}/api/quickbooks/refresh";
/**
 * Metadata for all the requests in the request builder.
 */
export const RefreshRequestBuilderRequestsMetadata: RequestsMetadata = {
    get: {
        uriTemplate: RefreshRequestBuilderUriTemplate,
        adapterMethodName: "sendNoResponseContent",
    },
};
/* tslint:enable */
/* eslint-enable */
