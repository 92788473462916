<script setup lang="ts">
import { useProfileStore } from "@/stores/profileStore";
import { storeToRefs } from "pinia";
import { useAuthStore } from "@/stores/authStore";

const profileStore = useProfileStore();
const authStore = useAuthStore();
const { organization } = storeToRefs(authStore);
const { showProfileModal } = storeToRefs(profileStore);


</script>


<template>
    <Dialog v-model:visible="showProfileModal" modal :class="$style.profileModal">
        <template #header>
            <div class="font-bold whitespace-nowrap">Organization Profile</div>
        </template>
        <Divider class="m-0 p-0" />
        <div class="p-4 pt-6">
            <OrgLogo />
            <p class="lg:max-w-[50%] my-4 text-grey-dark/80">Logo Image must be .JPG, .PNG, or .GIF and up to 512x512 pixels.</p>
            <Divider class="mb-4" />

            <div v-if="organization" :class="$style.profileInfo">
                <dl>
                    <dt class="font-bold">Organization Name</dt>
                    <dd>{{ organization.name }}</dd>
                </dl>
                <dl>
                    <dt class="font-bold">Billing Address</dt>
                    <dd v-if="organization.billingAddress1">{{organization.billingAddress1}}</dd>
                    <dd v-if="organization.billingAddress2">{{organization.billingAddress2}}</dd>
                    <dd><span v-if="organization.billingCity">{{organization.billingCity}},</span> <span v-if="organization.billingStateProvince">{{ organization.billingStateProvince }}</span> <span v-if="organization.billingPostalCode">{{ organization.billingPostalCode }}</span></dd>
                    <dd>{{ organization.billingCountry }}</dd>
                </dl>
                <dl v-if="organization.websiteUrl">
                    <dt class="font-bold">Website</dt>
                    <dd>{{organization.websiteUrl}}</dd>
                </dl>
                <dl v-if="organization.phoneNumber">
                    <dt>Phone</dt>
                    <dd>{{organization.phoneNumber}}</dd>
                </dl>
                <dl v-if="organization.email">
                    <dt class="font-bold">Email</dt>
                    <dd>{{organization.email}}</dd>
                </dl>




            </div>



        </div>


    </Dialog>
</template>


<style module>
.profileModal {
    @apply w-11/12 lg:w-1/2;


}

.header {
    @apply bg-lime-500;
}

.profileInfo {
    
    dl {
        @apply mb-4 text-lg;
    }

    dt {
        @apply font-medium text-grey-dark;
    }
    dd{
        @apply text-grey-dark/80
    }


}
</style>