import { useErrorStore} from "@/stores/errorStore";
import type {  ProblemDetails } from '@/types/ProblemDetails'

export function handleGlobalError(error : any) {
  
  // console.log('handleGlobalError:', error);
  // console.log( error.message )
  // console.log(JSON.stringify(error))
  
  const errorStore = useErrorStore();
  
  if(error.body !== undefined && error.title !== '') {
    const problemDetails: ProblemDetails = JSON.parse(JSON.stringify(error.body));
    errorStore.addError(problemDetails);
  }
  else if (error instanceof TypeError && error.message === 'Failed to fetch') {
    // This is a network error, which could be a connection refused error
    const err: ProblemDetails = {
      status: 500,
      title: 'Connection Refused',
      errors: [
        {
          name: 'Connection Refused',
          reason: 'The connection was refused when attempting to contact the requested resource.  We have been notified of the issue and are working to resolve it.  Please try again later.',
          code: '503'
        }
      ]
    };

    errorStore.addError(err);
  }
  else if (error.errors) {
    // Handle the specific error structure
    const reasons = Object.values(error.errors).map((err: any) => err.reason).join(', ');
    const err: ProblemDetails = {
      status: error.status,
      title: error.title,
      errors: [
        {
          name: 'Error',
          reason: reasons,
          code: error.status.toString()
        }
      ]
    };

    errorStore.addError(err);
  }
  else {
   
    const reason = error.status === 401 ? "You are not authorized to access this resource.  Please login and try again." : error.message;
    
    const err: ProblemDetails = {
      status: error.status,
      title: error.message,
      errors: [
        {
          name: error.name,
          reason: reason,
          code: error.status ? error.status.toString() : 'unknown error code'
        }
      ]
    };
    
    errorStore.addError(err);
  } 


  
}