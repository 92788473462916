/* tslint:disable */
/* eslint-disable */
// Generated by Microsoft Kiota
// @ts-ignore
import { createErrorResponseFromDiscriminatorValue, createInternalErrorResponseFromDiscriminatorValue, createPagedResultOfProductFromDiscriminatorValue, type ErrorResponse, type InternalErrorResponse, type PagedResultOfProduct } from '../../../models/';
// @ts-ignore
import { type BaseRequestBuilder, type Parsable, type ParsableFactory, type RequestConfiguration, type RequestInformation, type RequestsMetadata } from '@microsoft/kiota-abstractions';

/**
 * Builds and executes requests for operations under /api/products/search
 */
export interface SearchRequestBuilder extends BaseRequestBuilder<SearchRequestBuilder> {
    /**
     * Search products by search term with pagination
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {Promise<PagedResultOfProduct>}
     * @throws {ErrorResponse} error when the service returns a 400 status code
     * @throws {InternalErrorResponse} error when the service returns a 500 status code
     */
     get(requestConfiguration?: RequestConfiguration<SearchRequestBuilderGetQueryParameters> | undefined) : Promise<PagedResultOfProduct | undefined>;
    /**
     * Search products by search term with pagination
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {RequestInformation}
     */
     toGetRequestInformation(requestConfiguration?: RequestConfiguration<SearchRequestBuilderGetQueryParameters> | undefined) : RequestInformation;
}
/**
 * Search products by search term with pagination
 */
export interface SearchRequestBuilderGetQueryParameters {
    /**
     * Gets or sets the page number for the request. Default value is 1.
     */
    pageNumber?: number;
    /**
     * Gets or sets the page size for the request. Default value is 50.
     */
    pageSize?: number;
    /**
     * Search term to filter products
     */
    searchTerm?: string;
}
/**
 * Uri template for the request builder.
 */
export const SearchRequestBuilderUriTemplate = "{+baseurl}/api/products/search?pageNumber={pageNumber}&pageSize={pageSize}&searchTerm={searchTerm}";
/**
 * Metadata for all the requests in the request builder.
 */
export const SearchRequestBuilderRequestsMetadata: RequestsMetadata = {
    get: {
        uriTemplate: SearchRequestBuilderUriTemplate,
        responseBodyContentType: "application/json",
        errorMappings: {
            400: createErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
            500: createInternalErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
        },
        adapterMethodName: "send",
        responseBodyFactory:  createPagedResultOfProductFromDiscriminatorValue,
    },
};
/* tslint:enable */
/* eslint-enable */
