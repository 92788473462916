/* tslint:disable */
/* eslint-disable */
// Generated by Microsoft Kiota
// @ts-ignore
import { createCreateAccessRequestResponseFromDiscriminatorValue, createErrorResponseFromDiscriminatorValue, createInternalErrorResponseFromDiscriminatorValue, createPagedResultOfAccessRequestFromDiscriminatorValue, serializeCreateAccessRequestRequest, serializeCreateAccessRequestResponse, type CreateAccessRequestRequest, type CreateAccessRequestResponse, type ErrorResponse, type InternalErrorResponse, type PagedResultOfAccessRequest } from '../../../models/';
// @ts-ignore
import { ExistsRequestBuilderRequestsMetadata, type ExistsRequestBuilder } from './exists/';
// @ts-ignore
import { type WithAccessRequestItemRequestBuilder, WithAccessRequestItemRequestBuilderNavigationMetadata, WithAccessRequestItemRequestBuilderRequestsMetadata } from './item/';
// @ts-ignore
import { type BaseRequestBuilder, type KeysToExcludeForNavigationMetadata, type NavigationMetadata, type Parsable, type ParsableFactory, type RequestConfiguration, type RequestInformation, type RequestsMetadata } from '@microsoft/kiota-abstractions';

/**
 * Builds and executes requests for operations under /api/organizations/access-requests
 */
export interface AccessRequestsRequestBuilder extends BaseRequestBuilder<AccessRequestsRequestBuilder> {
    /**
     * The exists property
     */
    get exists(): ExistsRequestBuilder;
    /**
     * Gets an item from the MomentumAPI.api.organizations.accessRequests.item collection
     * @param accessRequestId The ID of the access request to retrieve
     * @returns {WithAccessRequestItemRequestBuilder}
     */
     byAccessRequestId(accessRequestId: string) : WithAccessRequestItemRequestBuilder;
    /**
     * List access requests for an organization with paging and filtering
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {Promise<PagedResultOfAccessRequest>}
     * @throws {ErrorResponse} error when the service returns a 400 status code
     * @throws {InternalErrorResponse} error when the service returns a 500 status code
     */
     get(requestConfiguration?: RequestConfiguration<AccessRequestsRequestBuilderGetQueryParameters> | undefined) : Promise<PagedResultOfAccessRequest | undefined>;
    /**
     * Creates a new access request for an organization
     * @param body The request body
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {Promise<CreateAccessRequestResponse>}
     * @throws {ErrorResponse} error when the service returns a 400 status code
     * @throws {InternalErrorResponse} error when the service returns a 500 status code
     */
     post(body: CreateAccessRequestRequest, requestConfiguration?: RequestConfiguration<object> | undefined) : Promise<CreateAccessRequestResponse | undefined>;
    /**
     * List access requests for an organization with paging and filtering
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {RequestInformation}
     */
     toGetRequestInformation(requestConfiguration?: RequestConfiguration<AccessRequestsRequestBuilderGetQueryParameters> | undefined) : RequestInformation;
    /**
     * Creates a new access request for an organization
     * @param body The request body
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {RequestInformation}
     */
     toPostRequestInformation(body: CreateAccessRequestRequest, requestConfiguration?: RequestConfiguration<object> | undefined) : RequestInformation;
}
/**
 * List access requests for an organization with paging and filtering
 */
export interface AccessRequestsRequestBuilderGetQueryParameters {
    /**
     * Gets or sets the filters for the request.Acceptable operators are: starts with, contains, notcontains, endswith, equals, notequalsCan filter on: CompanyName, EmailAddress, City, StateProvince, Country
     */
    filters?: string[];
    /**
     * Gets or sets the page number for the request. Default value is 1.
     */
    pageNumber?: number;
    /**
     * Gets or sets the page size for the request. Default value is 25.
     */
    pageSize?: number;
    /**
     * Gets or sets the sort direction. Can be "ASC" or "DESC". Default value is "asc".
     */
    sortDirection?: string;
    /**
     * Gets or sets the field to sort on. Default value is "CompanyName".
     */
    sortOn?: string;
}
/**
 * Uri template for the request builder.
 */
export const AccessRequestsRequestBuilderUriTemplate = "{+baseurl}/api/organizations/access-requests?filters={filters}&pageNumber={pageNumber}&pageSize={pageSize}&sortDirection={sortDirection}&sortOn={sortOn}";
/**
 * Metadata for all the navigation properties in the request builder.
 */
export const AccessRequestsRequestBuilderNavigationMetadata: Record<Exclude<keyof AccessRequestsRequestBuilder, KeysToExcludeForNavigationMetadata>, NavigationMetadata> = {
    byAccessRequestId: {
        requestsMetadata: WithAccessRequestItemRequestBuilderRequestsMetadata,
        navigationMetadata: WithAccessRequestItemRequestBuilderNavigationMetadata,
        pathParametersMappings: ["accessRequestId"],
    },
    exists: {
        requestsMetadata: ExistsRequestBuilderRequestsMetadata,
    },
};
/**
 * Metadata for all the requests in the request builder.
 */
export const AccessRequestsRequestBuilderRequestsMetadata: RequestsMetadata = {
    get: {
        uriTemplate: AccessRequestsRequestBuilderUriTemplate,
        responseBodyContentType: "application/json",
        errorMappings: {
            400: createErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
            500: createInternalErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
        },
        adapterMethodName: "send",
        responseBodyFactory:  createPagedResultOfAccessRequestFromDiscriminatorValue,
    },
    post: {
        uriTemplate: AccessRequestsRequestBuilderUriTemplate,
        responseBodyContentType: "application/json",
        errorMappings: {
            400: createErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
            500: createInternalErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
        },
        adapterMethodName: "send",
        responseBodyFactory:  createCreateAccessRequestResponseFromDiscriminatorValue,
        requestBodyContentType: "application/json",
        requestBodySerializer: serializeCreateAccessRequestRequest,
        requestInformationContentSetMethod: "setContentFromParsable",
    },
};
/* tslint:enable */
/* eslint-enable */
