/* tslint:disable */
/* eslint-disable */
// Generated by Microsoft Kiota
// @ts-ignore
import { createValidateConfig_ValidateConfigResponseFromDiscriminatorValue, type ValidateConfig_ValidateConfigResponse } from '../models/';
// @ts-ignore
import { type BaseRequestBuilder, type Parsable, type ParsableFactory, type RequestConfiguration, type RequestInformation, type RequestsMetadata } from '@microsoft/kiota-abstractions';

/**
 * Builds and executes requests for operations under /validate-config
 */
export interface ValidateConfigRequestBuilder extends BaseRequestBuilder<ValidateConfigRequestBuilder> {
    /**
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {Promise<ValidateConfig_ValidateConfigResponse>}
     */
     get(requestConfiguration?: RequestConfiguration<object> | undefined) : Promise<ValidateConfig_ValidateConfigResponse | undefined>;
    /**
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {RequestInformation}
     */
     toGetRequestInformation(requestConfiguration?: RequestConfiguration<object> | undefined) : RequestInformation;
}
/**
 * Uri template for the request builder.
 */
export const ValidateConfigRequestBuilderUriTemplate = "{+baseurl}/validate-config";
/**
 * Metadata for all the requests in the request builder.
 */
export const ValidateConfigRequestBuilderRequestsMetadata: RequestsMetadata = {
    get: {
        uriTemplate: ValidateConfigRequestBuilderUriTemplate,
        responseBodyContentType: "application/json",
        adapterMethodName: "send",
        responseBodyFactory:  createValidateConfig_ValidateConfigResponseFromDiscriminatorValue,
    },
};
/* tslint:enable */
/* eslint-enable */
