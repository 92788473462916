/* tslint:disable */
/* eslint-disable */
// Generated by Microsoft Kiota
// @ts-ignore
import { createErrorResponseFromDiscriminatorValue, createGetAccessRequestResponseFromDiscriminatorValue, createInternalErrorResponseFromDiscriminatorValue, type ErrorResponse, type GetAccessRequestResponse, type InternalErrorResponse } from '../../../../models/';
// @ts-ignore
import { ApproveRequestBuilderRequestsMetadata, type ApproveRequestBuilder } from './approve/';
// @ts-ignore
import { type BaseRequestBuilder, type KeysToExcludeForNavigationMetadata, type NavigationMetadata, type Parsable, type ParsableFactory, type RequestConfiguration, type RequestInformation, type RequestsMetadata } from '@microsoft/kiota-abstractions';

/**
 * Builds and executes requests for operations under /api/organizations/access-requests/{accessRequestId}
 */
export interface WithAccessRequestItemRequestBuilder extends BaseRequestBuilder<WithAccessRequestItemRequestBuilder> {
    /**
     * The approve property
     */
    get approve(): ApproveRequestBuilder;
    /**
     * Gets a specific access request by ID
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {Promise<GetAccessRequestResponse>}
     * @throws {ErrorResponse} error when the service returns a 400 status code
     * @throws {InternalErrorResponse} error when the service returns a 500 status code
     */
     get(requestConfiguration?: RequestConfiguration<object> | undefined) : Promise<GetAccessRequestResponse | undefined>;
    /**
     * Gets a specific access request by ID
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {RequestInformation}
     */
     toGetRequestInformation(requestConfiguration?: RequestConfiguration<object> | undefined) : RequestInformation;
}
/**
 * Uri template for the request builder.
 */
export const WithAccessRequestItemRequestBuilderUriTemplate = "{+baseurl}/api/organizations/access-requests/{accessRequestId}";
/**
 * Metadata for all the navigation properties in the request builder.
 */
export const WithAccessRequestItemRequestBuilderNavigationMetadata: Record<Exclude<keyof WithAccessRequestItemRequestBuilder, KeysToExcludeForNavigationMetadata>, NavigationMetadata> = {
    approve: {
        requestsMetadata: ApproveRequestBuilderRequestsMetadata,
    },
};
/**
 * Metadata for all the requests in the request builder.
 */
export const WithAccessRequestItemRequestBuilderRequestsMetadata: RequestsMetadata = {
    get: {
        uriTemplate: WithAccessRequestItemRequestBuilderUriTemplate,
        responseBodyContentType: "application/json",
        errorMappings: {
            400: createErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
            500: createInternalErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
        },
        adapterMethodName: "send",
        responseBodyFactory:  createGetAccessRequestResponseFromDiscriminatorValue,
    },
};
/* tslint:enable */
/* eslint-enable */
