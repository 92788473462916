/* tslint:disable */
/* eslint-disable */
// Generated by Microsoft Kiota
// @ts-ignore
import { createProblemDetailsFromDiscriminatorValue, createQuickBooksCompanyInfoResponse_CompanyInfoFromDiscriminatorValue, type ProblemDetails, type QuickBooksCompanyInfoResponse_CompanyInfo } from '../../../models/';
// @ts-ignore
import { type BaseRequestBuilder, type Parsable, type ParsableFactory, type RequestConfiguration, type RequestInformation, type RequestsMetadata } from '@microsoft/kiota-abstractions';

/**
 * Builds and executes requests for operations under /api/quickbooks/companyinfo
 */
export interface CompanyinfoRequestBuilder extends BaseRequestBuilder<CompanyinfoRequestBuilder> {
    /**
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {Promise<QuickBooksCompanyInfoResponse_CompanyInfo>}
     * @throws {ProblemDetails} error when the service returns a 400 status code
     */
     get(requestConfiguration?: RequestConfiguration<object> | undefined) : Promise<QuickBooksCompanyInfoResponse_CompanyInfo | undefined>;
    /**
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {RequestInformation}
     */
     toGetRequestInformation(requestConfiguration?: RequestConfiguration<object> | undefined) : RequestInformation;
}
/**
 * Uri template for the request builder.
 */
export const CompanyinfoRequestBuilderUriTemplate = "{+baseurl}/api/quickbooks/companyinfo";
/**
 * Metadata for all the requests in the request builder.
 */
export const CompanyinfoRequestBuilderRequestsMetadata: RequestsMetadata = {
    get: {
        uriTemplate: CompanyinfoRequestBuilderUriTemplate,
        responseBodyContentType: "application/json",
        errorMappings: {
            400: createProblemDetailsFromDiscriminatorValue as ParsableFactory<Parsable>,
        },
        adapterMethodName: "send",
        responseBodyFactory:  createQuickBooksCompanyInfoResponse_CompanyInfoFromDiscriminatorValue,
    },
};
/* tslint:enable */
/* eslint-enable */
