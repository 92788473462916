/* tslint:disable */
/* eslint-disable */
// Generated by Microsoft Kiota
// @ts-ignore
import { createErrorResponseFromDiscriminatorValue, createInternalErrorResponseFromDiscriminatorValue, createPagedResultOfShoppingCartFromDiscriminatorValue, type ErrorResponse, type InternalErrorResponse, type PagedResultOfShoppingCart } from '../../models/';
// @ts-ignore
import { ByCustomerRequestBuilderNavigationMetadata, type ByCustomerRequestBuilder } from './byCustomer/';
// @ts-ignore
import { CustomerNumberItemRequestBuilderNavigationMetadata, CustomerNumberItemRequestBuilderRequestsMetadata, type CustomerNumberItemRequestBuilder } from './item/';
// @ts-ignore
import { type BaseRequestBuilder, type KeysToExcludeForNavigationMetadata, type NavigationMetadata, type Parsable, type ParsableFactory, type RequestConfiguration, type RequestInformation, type RequestsMetadata } from '@microsoft/kiota-abstractions';

/**
 * Builds and executes requests for operations under /api/carts
 */
export interface CartsRequestBuilder extends BaseRequestBuilder<CartsRequestBuilder> {
    /**
     * The byCustomer property
     */
    get byCustomer(): ByCustomerRequestBuilder;
    /**
     * Gets an item from the MomentumAPI.api.carts.item collection
     * @param customerNumberId Unique identifier of the item
     * @returns {CustomerNumberItemRequestBuilder}
     */
     byCustomerNumberId(customerNumberId: string) : CustomerNumberItemRequestBuilder;
    /**
     * List shopping carts for an organization with paging and filtering
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {Promise<PagedResultOfShoppingCart>}
     * @throws {ErrorResponse} error when the service returns a 400 status code
     * @throws {InternalErrorResponse} error when the service returns a 500 status code
     */
     get(requestConfiguration?: RequestConfiguration<CartsRequestBuilderGetQueryParameters> | undefined) : Promise<PagedResultOfShoppingCart | undefined>;
    /**
     * List shopping carts for an organization with paging and filtering
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {RequestInformation}
     */
     toGetRequestInformation(requestConfiguration?: RequestConfiguration<CartsRequestBuilderGetQueryParameters> | undefined) : RequestInformation;
}
/**
 * List shopping carts for an organization with paging and filtering
 */
export interface CartsRequestBuilderGetQueryParameters {
    /**
     * Gets or sets the filters for the request.Acceptable operators are: starts with, contains, notcontains, endswith, equals, notequalsCan filter on: CustomerNumber, CompanyName, Status
     */
    filters?: string[];
    /**
     * Gets or sets the page number for the request. Default value is 1.
     */
    pageNumber?: number;
    /**
     * Gets or sets the page size for the request. Default value is 25.
     */
    pageSize?: number;
    /**
     * Gets or sets the sort direction. Can be "ASC" or "DESC". Default value is "asc".
     */
    sortDirection?: string;
    sortOn?: string;
}
/**
 * Uri template for the request builder.
 */
export const CartsRequestBuilderUriTemplate = "{+baseurl}/api/carts?filters={filters}&pageNumber={pageNumber}&pageSize={pageSize}&sortDirection={sortDirection}&sortOn={sortOn}";
/**
 * Metadata for all the navigation properties in the request builder.
 */
export const CartsRequestBuilderNavigationMetadata: Record<Exclude<keyof CartsRequestBuilder, KeysToExcludeForNavigationMetadata>, NavigationMetadata> = {
    byCustomerNumberId: {
        requestsMetadata: CustomerNumberItemRequestBuilderRequestsMetadata,
        navigationMetadata: CustomerNumberItemRequestBuilderNavigationMetadata,
        pathParametersMappings: ["customerNumber%2Did"],
    },
    byCustomer: {
        navigationMetadata: ByCustomerRequestBuilderNavigationMetadata,
    },
};
/**
 * Metadata for all the requests in the request builder.
 */
export const CartsRequestBuilderRequestsMetadata: RequestsMetadata = {
    get: {
        uriTemplate: CartsRequestBuilderUriTemplate,
        responseBodyContentType: "application/json",
        errorMappings: {
            400: createErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
            500: createInternalErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
        },
        adapterMethodName: "send",
        responseBodyFactory:  createPagedResultOfShoppingCartFromDiscriminatorValue,
    },
};
/* tslint:enable */
/* eslint-enable */
