/* tslint:disable */
/* eslint-disable */
// Generated by Microsoft Kiota
// @ts-ignore
import { createEnrichUserAccessTokenResponseFromDiscriminatorValue, createErrorResponseFromDiscriminatorValue, createInternalErrorResponseFromDiscriminatorValue, type EnrichUserAccessTokenResponse, type ErrorResponse, type InternalErrorResponse } from '../../../../models/';
// @ts-ignore
import { type BaseRequestBuilder, type Parsable, type ParsableFactory, type RequestConfiguration, type RequestInformation, type RequestsMetadata } from '@microsoft/kiota-abstractions';

/**
 * Builds and executes requests for operations under /api/auth/access/enrich-customer-token
 */
export interface EnrichCustomerTokenRequestBuilder extends BaseRequestBuilder<EnrichCustomerTokenRequestBuilder> {
    /**
     * Gets customer number for a user in a specific organization to enrich their access token
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {Promise<EnrichUserAccessTokenResponse>}
     * @throws {ErrorResponse} error when the service returns a 400 status code
     * @throws {InternalErrorResponse} error when the service returns a 500 status code
     */
     get(requestConfiguration?: RequestConfiguration<EnrichCustomerTokenRequestBuilderGetQueryParameters> | undefined) : Promise<EnrichUserAccessTokenResponse | undefined>;
    /**
     * Gets customer number for a user in a specific organization to enrich their access token
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {RequestInformation}
     */
     toGetRequestInformation(requestConfiguration?: RequestConfiguration<EnrichCustomerTokenRequestBuilderGetQueryParameters> | undefined) : RequestInformation;
}
/**
 * Gets customer number for a user in a specific organization to enrich their access token
 */
export interface EnrichCustomerTokenRequestBuilderGetQueryParameters {
    /**
     * The organization ID to get the customer number from
     */
    organizationId?: string;
    /**
     * The ID of the user to get the customer number for
     */
    userId?: string;
}
/**
 * Uri template for the request builder.
 */
export const EnrichCustomerTokenRequestBuilderUriTemplate = "{+baseurl}/api/auth/access/enrich-customer-token?organizationId={organizationId}&userId={userId}";
/**
 * Metadata for all the requests in the request builder.
 */
export const EnrichCustomerTokenRequestBuilderRequestsMetadata: RequestsMetadata = {
    get: {
        uriTemplate: EnrichCustomerTokenRequestBuilderUriTemplate,
        responseBodyContentType: "application/json",
        errorMappings: {
            400: createErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
            500: createInternalErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
        },
        adapterMethodName: "send",
        responseBodyFactory:  createEnrichUserAccessTokenResponseFromDiscriminatorValue,
    },
};
/* tslint:enable */
/* eslint-enable */
