import router from '@/router';
import createKindeClient from '@kinde-oss/kinde-auth-pkce-js';

let kindeClient: any;
let refreshPromise: Promise<string> | null = null;
const REFRESH_THRESHOLD = 60; // Refresh token if less than 60 seconds until expiry


export const initializeKindeClient = async () => {
  kindeClient = await createKindeClient({
    client_id: process.env.VITE_AUTH_CLIENTID,
    domain: process.env.VITE_AUTH_DOMAIN as string,
    redirect_uri: window.location.origin + '/auth/callback',
    logout_uri: import.meta.env.VITE_BASE_URL,
    audience: process.env.VITE_AUTH_API_AUDIENCE,
    is_dangerously_use_local_storage: true,
    on_redirect_callback: (user, appState) => {
      if (user) {
      } else {
        // render logged out view
        console.log('Login Fail')
        setTimeout(() => {
          router.push('/home')
        }, 5000);
      }
    }
  });
};

export const getKindeClient = () => kindeClient;

const getTokenExpiration = (token: string): number | null => {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const payload = JSON.parse(window.atob(base64));
    return payload.exp || null;
  } catch (error) {
    console.error('Error decoding token:', error);
    return null;
  }
};

export const getTokenWithRefresh = async (): Promise<string | null> => {
  if (!kindeClient) return null;
  
  try {
    const token = await kindeClient.getToken();
    if (!token) return null;

    const expiresAt = getTokenExpiration(token);    
    if (shouldRefreshToken(expiresAt)) {
      return silentRefresh();
    }
    
    return token;
  } catch (error) {
    console.error('Error getting token:', error);
    return null;
  }
};

const silentRefresh = async (): Promise<string | null> => {
  if (!refreshPromise) {
    refreshPromise = kindeClient.getToken({ ignoreCache: true })
      .finally(() => { refreshPromise = null; });
  }
  return refreshPromise;
};

const shouldRefreshToken = (expiresAt: number | null): boolean => {
  if (!expiresAt) return true;
  const now = Math.floor(Date.now() / 1000);
  return expiresAt - now < REFRESH_THRESHOLD;
};
