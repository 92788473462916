/* tslint:disable */
/* eslint-disable */
// Generated by Microsoft Kiota
// @ts-ignore
import { createErrorResponseFromDiscriminatorValue, createInternalErrorResponseFromDiscriminatorValue, createPagedResultOfUserCustomerFromDiscriminatorValue, type ErrorResponse, type InternalErrorResponse, type PagedResultOfUserCustomer } from '../../../../models/';
// @ts-ignore
import { type BaseRequestBuilder, type Parsable, type ParsableFactory, type RequestConfiguration, type RequestInformation, type RequestsMetadata } from '@microsoft/kiota-abstractions';

/**
 * Builds and executes requests for operations under /api/organizations/users/associations
 */
export interface AssociationsRequestBuilder extends BaseRequestBuilder<AssociationsRequestBuilder> {
    /**
     * List all user-customer associations for an organization with paging and filtering support
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {Promise<PagedResultOfUserCustomer>}
     * @throws {ErrorResponse} error when the service returns a 400 status code
     * @throws {InternalErrorResponse} error when the service returns a 500 status code
     */
     get(requestConfiguration?: RequestConfiguration<AssociationsRequestBuilderGetQueryParameters> | undefined) : Promise<PagedResultOfUserCustomer | undefined>;
    /**
     * List all user-customer associations for an organization with paging and filtering support
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {RequestInformation}
     */
     toGetRequestInformation(requestConfiguration?: RequestConfiguration<AssociationsRequestBuilderGetQueryParameters> | undefined) : RequestInformation;
}
/**
 * List all user-customer associations for an organization with paging and filtering support
 */
export interface AssociationsRequestBuilderGetQueryParameters {
    /**
     * Gets or sets the filters for the request. Can filter by UserId or CustomerNumber.Acceptable operators are: starts with, contains, notcontains, endswith, equals, notequals, gt, get, lt, lte
     */
    filters?: string[];
    /**
     * Gets or sets the page number for the request. Default value is 1.
     */
    pageNumber?: number;
    /**
     * Gets or sets the page size for the request. Default value is 25.
     */
    pageSize?: number;
    /**
     * Gets or sets the sort direction. Can be "ASC" or "DESC". Default value is "asc".
     */
    sortDirection?: string;
    /**
     * Gets or sets the field to sort on. Default value is "UserId".
     */
    sortOn?: string;
}
/**
 * Uri template for the request builder.
 */
export const AssociationsRequestBuilderUriTemplate = "{+baseurl}/api/organizations/users/associations?filters={filters}&pageNumber={pageNumber}&pageSize={pageSize}&sortDirection={sortDirection}&sortOn={sortOn}";
/**
 * Metadata for all the requests in the request builder.
 */
export const AssociationsRequestBuilderRequestsMetadata: RequestsMetadata = {
    get: {
        uriTemplate: AssociationsRequestBuilderUriTemplate,
        responseBodyContentType: "application/json",
        errorMappings: {
            400: createErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
            500: createInternalErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
        },
        adapterMethodName: "send",
        responseBodyFactory:  createPagedResultOfUserCustomerFromDiscriminatorValue,
    },
};
/* tslint:enable */
/* eslint-enable */
