/* tslint:disable */
/* eslint-disable */
// Generated by Microsoft Kiota
// @ts-ignore
import { createErrorResponseFromDiscriminatorValue, createInternalErrorResponseFromDiscriminatorValue, type ErrorResponse, type InternalErrorResponse } from '../../../models/';
// @ts-ignore
import { MultipartBody, serializeMultipartBody, type BaseRequestBuilder, type Parsable, type ParsableFactory, type RequestConfiguration, type RequestInformation, type RequestsMetadata } from '@microsoft/kiota-abstractions';

/**
 * Builds and executes requests for operations under /api/images/from-local
 */
export interface FromLocalRequestBuilder extends BaseRequestBuilder<FromLocalRequestBuilder> {
    /**
     * Process a list of images, if the ExtractSkuFromImageName is set to true it will try to find the product using the image name and assocaite it
     * @param body The request body
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @throws {ErrorResponse} error when the service returns a 400 status code
     * @throws {InternalErrorResponse} error when the service returns a 500 status code
     */
     post(body: MultipartBody, requestConfiguration?: RequestConfiguration<object> | undefined) : Promise<void>;
    /**
     * Process a list of images, if the ExtractSkuFromImageName is set to true it will try to find the product using the image name and assocaite it
     * @param body The request body
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {RequestInformation}
     */
     toPostRequestInformation(body: MultipartBody, requestConfiguration?: RequestConfiguration<object> | undefined) : RequestInformation;
}
/**
 * Uri template for the request builder.
 */
export const FromLocalRequestBuilderUriTemplate = "{+baseurl}/api/images/from-local";
/**
 * Metadata for all the requests in the request builder.
 */
export const FromLocalRequestBuilderRequestsMetadata: RequestsMetadata = {
    post: {
        uriTemplate: FromLocalRequestBuilderUriTemplate,
        responseBodyContentType: "application/json, application/problem+json",
        errorMappings: {
            400: createErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
            500: createInternalErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
        },
        adapterMethodName: "sendNoResponseContent",
        requestBodyContentType: "multipart/form-data",
        requestBodySerializer: serializeMultipartBody,
        requestInformationContentSetMethod: "setContentFromParsable",
    },
};
/* tslint:enable */
/* eslint-enable */
