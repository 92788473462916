/* tslint:disable */
/* eslint-disable */
// Generated by Microsoft Kiota
// @ts-ignore
import { TestRequestBuilderRequestsMetadata, type TestRequestBuilder } from './test/';
// @ts-ignore
import { type BaseRequestBuilder, type KeysToExcludeForNavigationMetadata, type NavigationMetadata } from '@microsoft/kiota-abstractions';

/**
 * Builds and executes requests for operations under /api/jobs
 */
export interface JobsRequestBuilder extends BaseRequestBuilder<JobsRequestBuilder> {
    /**
     * The test property
     */
    get test(): TestRequestBuilder;
}
/**
 * Uri template for the request builder.
 */
export const JobsRequestBuilderUriTemplate = "{+baseurl}/api/jobs";
/**
 * Metadata for all the navigation properties in the request builder.
 */
export const JobsRequestBuilderNavigationMetadata: Record<Exclude<keyof JobsRequestBuilder, KeysToExcludeForNavigationMetadata>, NavigationMetadata> = {
    test: {
        requestsMetadata: TestRequestBuilderRequestsMetadata,
    },
};
/* tslint:enable */
/* eslint-enable */
