/* tslint:disable */
/* eslint-disable */
// Generated by Microsoft Kiota
// @ts-ignore
import { EnrichRequestBuilderRequestsMetadata, type EnrichRequestBuilder } from './enrich/';
// @ts-ignore
import { type BaseRequestBuilder, type KeysToExcludeForNavigationMetadata, type NavigationMetadata } from '@microsoft/kiota-abstractions';

/**
 * Builds and executes requests for operations under /api/auth/m2m
 */
export interface M2mRequestBuilder extends BaseRequestBuilder<M2mRequestBuilder> {
    /**
     * The enrich property
     */
    get enrich(): EnrichRequestBuilder;
}
/**
 * Uri template for the request builder.
 */
export const M2mRequestBuilderUriTemplate = "{+baseurl}/api/auth/m2m";
/**
 * Metadata for all the navigation properties in the request builder.
 */
export const M2mRequestBuilderNavigationMetadata: Record<Exclude<keyof M2mRequestBuilder, KeysToExcludeForNavigationMetadata>, NavigationMetadata> = {
    enrich: {
        requestsMetadata: EnrichRequestBuilderRequestsMetadata,
    },
};
/* tslint:enable */
/* eslint-enable */
