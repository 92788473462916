/* tslint:disable */
/* eslint-disable */
// Generated by Microsoft Kiota
// @ts-ignore
import { AuthRequestBuilderNavigationMetadata, type AuthRequestBuilder } from './auth/';
// @ts-ignore
import { CartsRequestBuilderNavigationMetadata, CartsRequestBuilderRequestsMetadata, type CartsRequestBuilder } from './carts/';
// @ts-ignore
import { CategoriesRequestBuilderNavigationMetadata, CategoriesRequestBuilderRequestsMetadata, type CategoriesRequestBuilder } from './categories/';
// @ts-ignore
import { ConfigRequestBuilderRequestsMetadata, type ConfigRequestBuilder } from './config/';
// @ts-ignore
import { CustomersRequestBuilderNavigationMetadata, CustomersRequestBuilderRequestsMetadata, type CustomersRequestBuilder } from './customers/';
// @ts-ignore
import { DatamappingsRequestBuilderNavigationMetadata, DatamappingsRequestBuilderRequestsMetadata, type DatamappingsRequestBuilder } from './datamappings/';
// @ts-ignore
import { ImagesRequestBuilderNavigationMetadata, ImagesRequestBuilderRequestsMetadata, type ImagesRequestBuilder } from './images/';
// @ts-ignore
import { JobsRequestBuilderNavigationMetadata, type JobsRequestBuilder } from './jobs/';
// @ts-ignore
import { OrdersRequestBuilderNavigationMetadata, OrdersRequestBuilderRequestsMetadata, type OrdersRequestBuilder } from './orders/';
// @ts-ignore
import { OrganizationRequestBuilderNavigationMetadata, OrganizationRequestBuilderRequestsMetadata, type OrganizationRequestBuilder } from './organization/';
// @ts-ignore
import { OrganizationsRequestBuilderNavigationMetadata, OrganizationsRequestBuilderRequestsMetadata, type OrganizationsRequestBuilder } from './organizations/';
// @ts-ignore
import { ProductsRequestBuilderNavigationMetadata, ProductsRequestBuilderRequestsMetadata, type ProductsRequestBuilder } from './products/';
// @ts-ignore
import { QuickbooksRequestBuilderNavigationMetadata, type QuickbooksRequestBuilder } from './quickbooks/';
// @ts-ignore
import { SalesrepsRequestBuilderNavigationMetadata, type SalesrepsRequestBuilder } from './salesreps/';
// @ts-ignore
import { type UsersRequestBuilder, UsersRequestBuilderNavigationMetadata, UsersRequestBuilderRequestsMetadata } from './users/';
// @ts-ignore
import { type BaseRequestBuilder, type KeysToExcludeForNavigationMetadata, type NavigationMetadata } from '@microsoft/kiota-abstractions';

/**
 * Builds and executes requests for operations under /api
 */
export interface ApiRequestBuilder extends BaseRequestBuilder<ApiRequestBuilder> {
    /**
     * The auth property
     */
    get auth(): AuthRequestBuilder;
    /**
     * The carts property
     */
    get carts(): CartsRequestBuilder;
    /**
     * The categories property
     */
    get categories(): CategoriesRequestBuilder;
    /**
     * The config property
     */
    get config(): ConfigRequestBuilder;
    /**
     * The customers property
     */
    get customers(): CustomersRequestBuilder;
    /**
     * The datamappings property
     */
    get datamappings(): DatamappingsRequestBuilder;
    /**
     * The images property
     */
    get images(): ImagesRequestBuilder;
    /**
     * The jobs property
     */
    get jobs(): JobsRequestBuilder;
    /**
     * The orders property
     */
    get orders(): OrdersRequestBuilder;
    /**
     * The organization property
     */
    get organization(): OrganizationRequestBuilder;
    /**
     * The organizations property
     */
    get organizations(): OrganizationsRequestBuilder;
    /**
     * The products property
     */
    get products(): ProductsRequestBuilder;
    /**
     * The quickbooks property
     */
    get quickbooks(): QuickbooksRequestBuilder;
    /**
     * The salesreps property
     */
    get salesreps(): SalesrepsRequestBuilder;
    /**
     * The users property
     */
    get users(): UsersRequestBuilder;
}
/**
 * Uri template for the request builder.
 */
export const ApiRequestBuilderUriTemplate = "{+baseurl}/api";
/**
 * Metadata for all the navigation properties in the request builder.
 */
export const ApiRequestBuilderNavigationMetadata: Record<Exclude<keyof ApiRequestBuilder, KeysToExcludeForNavigationMetadata>, NavigationMetadata> = {
    auth: {
        navigationMetadata: AuthRequestBuilderNavigationMetadata,
    },
    carts: {
        requestsMetadata: CartsRequestBuilderRequestsMetadata,
        navigationMetadata: CartsRequestBuilderNavigationMetadata,
    },
    categories: {
        requestsMetadata: CategoriesRequestBuilderRequestsMetadata,
        navigationMetadata: CategoriesRequestBuilderNavigationMetadata,
    },
    config: {
        requestsMetadata: ConfigRequestBuilderRequestsMetadata,
    },
    customers: {
        requestsMetadata: CustomersRequestBuilderRequestsMetadata,
        navigationMetadata: CustomersRequestBuilderNavigationMetadata,
    },
    datamappings: {
        requestsMetadata: DatamappingsRequestBuilderRequestsMetadata,
        navigationMetadata: DatamappingsRequestBuilderNavigationMetadata,
    },
    images: {
        requestsMetadata: ImagesRequestBuilderRequestsMetadata,
        navigationMetadata: ImagesRequestBuilderNavigationMetadata,
    },
    jobs: {
        navigationMetadata: JobsRequestBuilderNavigationMetadata,
    },
    orders: {
        requestsMetadata: OrdersRequestBuilderRequestsMetadata,
        navigationMetadata: OrdersRequestBuilderNavigationMetadata,
    },
    organization: {
        requestsMetadata: OrganizationRequestBuilderRequestsMetadata,
        navigationMetadata: OrganizationRequestBuilderNavigationMetadata,
    },
    organizations: {
        requestsMetadata: OrganizationsRequestBuilderRequestsMetadata,
        navigationMetadata: OrganizationsRequestBuilderNavigationMetadata,
    },
    products: {
        requestsMetadata: ProductsRequestBuilderRequestsMetadata,
        navigationMetadata: ProductsRequestBuilderNavigationMetadata,
    },
    quickbooks: {
        navigationMetadata: QuickbooksRequestBuilderNavigationMetadata,
    },
    salesreps: {
        navigationMetadata: SalesrepsRequestBuilderNavigationMetadata,
    },
    users: {
        requestsMetadata: UsersRequestBuilderRequestsMetadata,
        navigationMetadata: UsersRequestBuilderNavigationMetadata,
    },
};
/* tslint:enable */
/* eslint-enable */
