/* tslint:disable */
/* eslint-disable */
// Generated by Microsoft Kiota
// @ts-ignore
import { createCreateApiAccessResponseFromDiscriminatorValue, createErrorResponseFromDiscriminatorValue, createGetApiAccessResponseFromDiscriminatorValue, createInternalErrorResponseFromDiscriminatorValue, type CreateApiAccessResponse, type ErrorResponse, type GetApiAccessResponse, type InternalErrorResponse } from '../../../models/';
// @ts-ignore
import { type BaseRequestBuilder, type Parsable, type ParsableFactory, type RequestConfiguration, type RequestInformation, type RequestsMetadata } from '@microsoft/kiota-abstractions';

/**
 * Builds and executes requests for operations under /api/organization/api-access
 */
export interface ApiAccessRequestBuilder extends BaseRequestBuilder<ApiAccessRequestBuilder> {
    /**
     * Get Api Access for Organization
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {Promise<GetApiAccessResponse>}
     * @throws {ErrorResponse} error when the service returns a 400 status code
     * @throws {InternalErrorResponse} error when the service returns a 500 status code
     */
     get(requestConfiguration?: RequestConfiguration<object> | undefined) : Promise<GetApiAccessResponse | undefined>;
    /**
     * Create Api Access for Organization
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {Promise<CreateApiAccessResponse>}
     * @throws {ErrorResponse} error when the service returns a 400 status code
     * @throws {InternalErrorResponse} error when the service returns a 500 status code
     */
     post(requestConfiguration?: RequestConfiguration<object> | undefined) : Promise<CreateApiAccessResponse | undefined>;
    /**
     * Get Api Access for Organization
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {RequestInformation}
     */
     toGetRequestInformation(requestConfiguration?: RequestConfiguration<object> | undefined) : RequestInformation;
    /**
     * Create Api Access for Organization
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {RequestInformation}
     */
     toPostRequestInformation(requestConfiguration?: RequestConfiguration<object> | undefined) : RequestInformation;
}
/**
 * Uri template for the request builder.
 */
export const ApiAccessRequestBuilderUriTemplate = "{+baseurl}/api/organization/api-access";
/**
 * Metadata for all the requests in the request builder.
 */
export const ApiAccessRequestBuilderRequestsMetadata: RequestsMetadata = {
    get: {
        uriTemplate: ApiAccessRequestBuilderUriTemplate,
        responseBodyContentType: "application/json",
        errorMappings: {
            400: createErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
            500: createInternalErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
        },
        adapterMethodName: "send",
        responseBodyFactory:  createGetApiAccessResponseFromDiscriminatorValue,
    },
    post: {
        uriTemplate: ApiAccessRequestBuilderUriTemplate,
        responseBodyContentType: "application/json",
        errorMappings: {
            400: createErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
            500: createInternalErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
        },
        adapterMethodName: "send",
        responseBodyFactory:  createCreateApiAccessResponseFromDiscriminatorValue,
    },
};
/* tslint:enable */
/* eslint-enable */
