<script lang="ts" setup>
import { ref, onMounted } from "vue";

import { Menu } from 'primevue';
import { useLayout } from '@/layout/composables/layout';
import { useRouter } from 'vue-router';
import { handleGlobalError } from '@/composables/globalErrorHandler';
import { useAuthStore } from '@/stores/authStore';
import { useProfileStore } from "@/stores/profileStore";
import { storeToRefs } from 'pinia';
import ProfileModal from "../profile/ProfileModal.vue";

const { layoutState, isSlim } = useLayout();
const authStore = useAuthStore()
const profileStore = useProfileStore();
const router = useRouter();

const { logoUrl } = storeToRefs(authStore);

const visibleFull = ref(false);
const menu = ref();
const menuVisible = ref(false);
const items = ref([
    {
        label: 'Profile',
        icon: 'pi pi-user',
        command: () => {
            profileStore.openProfileModal();
        }
    },

    {
        label: 'Manage Subscription',
        icon: 'pi pi-credit-card',
        command: () => {
            manageSubscription();
        }
    },

    {
        label: 'API Access',
        icon: 'pi pi-cog',
        route: '/api-access',
    },

    {
        label: 'Sign Out',
        icon: 'pi pi-power-off',
        command: () => {
            logout();
        }
    }


]);


onMounted(() => {
    authStore.getLogoUrl();
})


const manageSubscriptionURL = ref('');
const manageSubscription = async () => {
    layoutState.profileSidebarVisible = false;
    try {
        const response = await authStore.getPortalSessionLink();
        manageSubscriptionURL.value = response?.sessionUrl ?? '';
        visibleFull.value = true;
    }
    catch (error) {
        handleGlobalError(error);
    }
}

const toggle = (event: Event) => {
    menu.value.toggle(event);
    menuVisible.value = menu.value.overlayVisible;
};

const logout = async () => {
    await authStore.logout();
}





</script>

<template>
    <div :class="`${$style.sidebarAccountMenu} ${isSlim ? $style.slim : ''}`">
        <ProfileModal />


        <Transition name="menuLogo" mode="out-in">
            <Button v-if="isSlim" :class="$style.menuBtn" type="button" severity="secondary" @click="toggle" aria-haspopup="true" aria-controls="overlay_menu">
                <div class="flex items-center">
                    <div v-if="logoUrl" :class="`${$style.logoWrap}`">
                        <img :src="logoUrl" :alt="authStore.user?.given_name" class="p-1.5" />
                    </div>
                    <i v-else class="pi pi-user"></i>
                </div>
            </Button>
            <Button v-else :class="$style.menuBtn" type="button" severity="secondary" @click="toggle" aria-haspopup="true" aria-controls="overlay_menu">
                <div class="flex items-center">
                    <div v-if="logoUrl" :class="$style.logoWrap">
                        <img :src="logoUrl" :alt="authStore.user?.given_name" class="p-1.5" />
                    </div>
                    <i v-else class="pi pi-user pl-2 pr-4"></i>
                    <span  class="">{{ authStore.user?.given_name }} {{ authStore.user?.family_name }} 
                        <br/>{{ authStore.currentOrgId}} {{authStore.currentOrg}}
                    </span>
                </div>
                <i :class="`pi pi-chevron-up ${$style.menuArrow} ${menuVisible ? $style.spin : ''}`"></i>
            </Button>

        </Transition>


        <Menu ref="menu" id="overlay_menu" :model="items" :popup="true">
            <template #item="{ item, props }">
                <router-link v-if="item.route" v-slot="{ href, navigate }" :to="item.route" custom>
                    <a :href="href" v-bind="props.action" @click="navigate">
                        <span :class="item.icon" />
                        <span class="ml-2">{{ item.label }}</span>
                    </a>
                </router-link>
                <div v-else-if="item.command" v-bind="props.action">
                    <span :class="item.icon" />
                    <span class="ml-2">{{ item.label }}</span>
                </div>
            </template>
        </Menu>
    </div>
    <Drawer v-model:visible="visibleFull" :baseZIndex="1000" position="full">
        <iframe :src="manageSubscriptionURL" style="width: 100%; height: 100%; border: none;"></iframe>
    </Drawer>
</template>

<style module>
.sidebarAccountMenu {
    @apply w-full flex justify-center items-center py-4 px-4;

    &.slim {
        @apply px-0;
    }
}

.menuBtn {
    @apply w-full justify-between items-center pl-1 pr-3;
}

.menuArrow {
    @apply transition-transform duration-300 ease-in-out;

    &.spin {
        @apply rotate-180;
    }
}

.logoWrap {
    @apply w-14 h-14 rounded-full mr-3 overflow-hidden border;
}

.slim {
    .menuBtn {
        @apply px-1 justify-center w-auto hover:bg-transparent hover:border-transparent ;
    }
    .logoWrap{
        @apply mr-0 hover:bg-white ;
    }
}
</style>