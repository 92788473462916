/* tslint:disable */
/* eslint-disable */
// Generated by Microsoft Kiota
// @ts-ignore
import { createQuickBooksTokenResponseFromDiscriminatorValue, serializeQuickBooksTokenRequest, serializeQuickBooksTokenResponse, type QuickBooksTokenRequest, type QuickBooksTokenResponse } from '../../../models/';
// @ts-ignore
import { type BaseRequestBuilder, type Parsable, type ParsableFactory, type RequestConfiguration, type RequestInformation, type RequestsMetadata } from '@microsoft/kiota-abstractions';

/**
 * Builds and executes requests for operations under /api/quickbooks/callback
 */
export interface CallbackRequestBuilder extends BaseRequestBuilder<CallbackRequestBuilder> {
    /**
     * @param body The request body
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {Promise<QuickBooksTokenResponse>}
     */
     post(body: QuickBooksTokenRequest, requestConfiguration?: RequestConfiguration<object> | undefined) : Promise<QuickBooksTokenResponse | undefined>;
    /**
     * @param body The request body
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {RequestInformation}
     */
     toPostRequestInformation(body: QuickBooksTokenRequest, requestConfiguration?: RequestConfiguration<object> | undefined) : RequestInformation;
}
/**
 * Uri template for the request builder.
 */
export const CallbackRequestBuilderUriTemplate = "{+baseurl}/api/quickbooks/callback";
/**
 * Metadata for all the requests in the request builder.
 */
export const CallbackRequestBuilderRequestsMetadata: RequestsMetadata = {
    post: {
        uriTemplate: CallbackRequestBuilderUriTemplate,
        responseBodyContentType: "application/json",
        adapterMethodName: "send",
        responseBodyFactory:  createQuickBooksTokenResponseFromDiscriminatorValue,
        requestBodyContentType: "application/json",
        requestBodySerializer: serializeQuickBooksTokenRequest,
        requestInformationContentSetMethod: "setContentFromParsable",
    },
};
/* tslint:enable */
/* eslint-enable */
