/* tslint:disable */
/* eslint-disable */
// Generated by Microsoft Kiota
// @ts-ignore
import { AuthRequestBuilderRequestsMetadata, type AuthRequestBuilder } from './auth/';
// @ts-ignore
import { CallbackRequestBuilderRequestsMetadata, type CallbackRequestBuilder } from './callback/';
// @ts-ignore
import { CompanyinfoRequestBuilderRequestsMetadata, type CompanyinfoRequestBuilder } from './companyinfo/';
// @ts-ignore
import { ConnectedRequestBuilderRequestsMetadata, type ConnectedRequestBuilder } from './connected/';
// @ts-ignore
import { RefreshRequestBuilderRequestsMetadata, type RefreshRequestBuilder } from './refresh/';
// @ts-ignore
import { type WebhooksRequestBuilder, WebhooksRequestBuilderRequestsMetadata } from './webhooks/';
// @ts-ignore
import { type BaseRequestBuilder, type KeysToExcludeForNavigationMetadata, type NavigationMetadata } from '@microsoft/kiota-abstractions';

/**
 * Builds and executes requests for operations under /api/quickbooks
 */
export interface QuickbooksRequestBuilder extends BaseRequestBuilder<QuickbooksRequestBuilder> {
    /**
     * The auth property
     */
    get auth(): AuthRequestBuilder;
    /**
     * The callback property
     */
    get callback(): CallbackRequestBuilder;
    /**
     * The companyinfo property
     */
    get companyinfo(): CompanyinfoRequestBuilder;
    /**
     * The connected property
     */
    get connected(): ConnectedRequestBuilder;
    /**
     * The refresh property
     */
    get refresh(): RefreshRequestBuilder;
    /**
     * The webhooks property
     */
    get webhooks(): WebhooksRequestBuilder;
}
/**
 * Uri template for the request builder.
 */
export const QuickbooksRequestBuilderUriTemplate = "{+baseurl}/api/quickbooks";
/**
 * Metadata for all the navigation properties in the request builder.
 */
export const QuickbooksRequestBuilderNavigationMetadata: Record<Exclude<keyof QuickbooksRequestBuilder, KeysToExcludeForNavigationMetadata>, NavigationMetadata> = {
    auth: {
        requestsMetadata: AuthRequestBuilderRequestsMetadata,
    },
    callback: {
        requestsMetadata: CallbackRequestBuilderRequestsMetadata,
    },
    companyinfo: {
        requestsMetadata: CompanyinfoRequestBuilderRequestsMetadata,
    },
    connected: {
        requestsMetadata: ConnectedRequestBuilderRequestsMetadata,
    },
    refresh: {
        requestsMetadata: RefreshRequestBuilderRequestsMetadata,
    },
    webhooks: {
        requestsMetadata: WebhooksRequestBuilderRequestsMetadata,
    },
};
/* tslint:enable */
/* eslint-enable */
