/* tslint:disable */
/* eslint-disable */
// Generated by Microsoft Kiota
// @ts-ignore
import { AccessRequestBuilderNavigationMetadata, type AccessRequestBuilder } from './access/';
// @ts-ignore
import { LoginRequestBuilderRequestsMetadata, type LoginRequestBuilder } from './login/';
// @ts-ignore
import { M2mRequestBuilderNavigationMetadata, type M2mRequestBuilder } from './m2m/';
// @ts-ignore
import { ReadmeRequestBuilderRequestsMetadata, type ReadmeRequestBuilder } from './readme/';
// @ts-ignore
import { type BaseRequestBuilder, type KeysToExcludeForNavigationMetadata, type NavigationMetadata } from '@microsoft/kiota-abstractions';

/**
 * Builds and executes requests for operations under /api/auth
 */
export interface AuthRequestBuilder extends BaseRequestBuilder<AuthRequestBuilder> {
    /**
     * The access property
     */
    get access(): AccessRequestBuilder;
    /**
     * The login property
     */
    get login(): LoginRequestBuilder;
    /**
     * The m2m property
     */
    get m2m(): M2mRequestBuilder;
    /**
     * The readme property
     */
    get readme(): ReadmeRequestBuilder;
}
/**
 * Uri template for the request builder.
 */
export const AuthRequestBuilderUriTemplate = "{+baseurl}/api/auth";
/**
 * Metadata for all the navigation properties in the request builder.
 */
export const AuthRequestBuilderNavigationMetadata: Record<Exclude<keyof AuthRequestBuilder, KeysToExcludeForNavigationMetadata>, NavigationMetadata> = {
    access: {
        navigationMetadata: AccessRequestBuilderNavigationMetadata,
    },
    login: {
        requestsMetadata: LoginRequestBuilderRequestsMetadata,
    },
    m2m: {
        navigationMetadata: M2mRequestBuilderNavigationMetadata,
    },
    readme: {
        requestsMetadata: ReadmeRequestBuilderRequestsMetadata,
    },
};
/* tslint:enable */
/* eslint-enable */
