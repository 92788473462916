import { defineStore } from "pinia";
import { ref } from "vue";
import { ApiClientFactory } from "@/stores/apiClientFactory";
import { UploadManyImagesRequest, ImageUploadItem, DeleteManyImagesRequest } from "@/api-client/models";
import { handleGlobalError } from "@/composables/globalErrorHandler";
import { useAuthStore } from '@/stores/authStore';


export const useImageStore = defineStore('imageStore', () => {
    
    const authStore = useAuthStore();
    const clientFactory = ApiClientFactory.getInstance();
    const client = clientFactory.getClient();

    //State
    const orgImageLoading = ref(false);
    const loading = ref(false);
    const imagesResponse = ref<any>(null);

    //Actions
    const createOrganizationImage = async (createOrganizationImageRequest: FormData) => {
        orgImageLoading.value = true;
        try {
            const authToken = await authStore.getAuthorizationToken();
            const response = await fetch('https://api-dev.achievemomentum.com/api/images/organization-logo', {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + authToken,
                },
                body: createOrganizationImageRequest, 
            });
        } catch (error) {
            handleGlobalError(error);
        } finally {
            orgImageLoading.value = false;
        }
    }

    // Action to upload multiple images
    const importImagesFromRemote = async (images: ImageUploadItem[]) => {
        const uploadRequest: UploadManyImagesRequest = {
            images: images
        };
        try {
            const response = await client.api.images.fromRemote.post(uploadRequest);
            return response;
        } catch (error) {
            handleGlobalError(error);
        }
    };

    // Action to list images
    const listImages = async (pagingParameters: any) => {
        loading.value = true;
        imagesResponse.value = null;
        try {
            const params = {
                queryParameters: {
                    pageNumber: pagingParameters.PageNumber,
                    pageSize: pagingParameters.rows,
                    sortOn: pagingParameters.SortFieldProperCase,
                    sortDirection: pagingParameters.SortOrderAsString,
                    filters: pagingParameters.CleanedFilters ? [JSON.stringify(pagingParameters.CleanedFilters)] : []
                }
            };
            console.log('params', params);
            const response = await client.api.images.get(params);
            imagesResponse.value = response;
        } catch (error) {
            handleGlobalError(error);
        } finally {
            loading.value = false;
        }
    };

    // Action to delete many images
    const deleteImages = async (deleteImages: DeleteManyImagesRequest) => {
        try {
            const response = await client.api.images.delete(deleteImages);
            return response;
        } catch (error) {
            handleGlobalError(error);
        }
    };

    // Action to delete an image
    const deleteImage = async (imageId: string) => {
        try {
            const deleteRequest: DeleteManyImagesRequest = {
                ids: imageId
            };
            const response = await client.api.images.delete(deleteRequest);
            return response;
        } catch (error) {
            handleGlobalError(error);
        }
    };

    return {
        orgImageLoading,
        loading,
        imagesResponse,
        createOrganizationImage,
        importImagesFromRemote,
        listImages,
        deleteImage,
        deleteImages
    };
});
