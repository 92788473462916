/* tslint:disable */
/* eslint-disable */
// Generated by Microsoft Kiota
// @ts-ignore
import { createErrorResponseFromDiscriminatorValue, createInternalErrorResponseFromDiscriminatorValue, createUserCustomerFromDiscriminatorValue, type ErrorResponse, type InternalErrorResponse, type UserCustomer } from '../../../models/';
// @ts-ignore
import { type BaseRequestBuilder, type Parsable, type ParsableFactory, type RequestConfiguration, type RequestInformation, type RequestsMetadata } from '@microsoft/kiota-abstractions';

/**
 * Builds and executes requests for operations under /api/users/customer-association
 */
export interface CustomerAssociationRequestBuilder extends BaseRequestBuilder<CustomerAssociationRequestBuilder> {
    /**
     * Gets the customer association for a user in a specific organization
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {Promise<UserCustomer>}
     * @throws {ErrorResponse} error when the service returns a 400 status code
     * @throws {InternalErrorResponse} error when the service returns a 500 status code
     */
     get(requestConfiguration?: RequestConfiguration<object> | undefined) : Promise<UserCustomer | undefined>;
    /**
     * Gets the customer association for a user in a specific organization
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {RequestInformation}
     */
     toGetRequestInformation(requestConfiguration?: RequestConfiguration<object> | undefined) : RequestInformation;
}
/**
 * Uri template for the request builder.
 */
export const CustomerAssociationRequestBuilderUriTemplate = "{+baseurl}/api/users/customer-association";
/**
 * Metadata for all the requests in the request builder.
 */
export const CustomerAssociationRequestBuilderRequestsMetadata: RequestsMetadata = {
    get: {
        uriTemplate: CustomerAssociationRequestBuilderUriTemplate,
        responseBodyContentType: "application/json",
        errorMappings: {
            400: createErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
            500: createInternalErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
        },
        adapterMethodName: "send",
        responseBodyFactory:  createUserCustomerFromDiscriminatorValue,
    },
};
/* tslint:enable */
/* eslint-enable */
