/* tslint:disable */
/* eslint-disable */
// Generated by Microsoft Kiota
// @ts-ignore
import { createErrorResponseFromDiscriminatorValue, createImageDetailFromDiscriminatorValue, createInternalErrorResponseFromDiscriminatorValue, createProblemDetailsFromDiscriminatorValue, type ErrorResponse, type ImageDetail, type InternalErrorResponse, type ProblemDetails } from '../../../models/';
// @ts-ignore
import { type BaseRequestBuilder, type Parsable, type ParsableFactory, type RequestConfiguration, type RequestInformation, type RequestsMetadata } from '@microsoft/kiota-abstractions';

/**
 * Builds and executes requests for operations under /api/images/{id}
 */
export interface ImagesItemRequestBuilder extends BaseRequestBuilder<ImagesItemRequestBuilder> {
    /**
     * Retrieves the details of an image using its ID
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {Promise<ImageDetail>}
     * @throws {ProblemDetails} error when the service returns a 400 status code
     * @throws {ErrorResponse} error when the service returns a 404 status code
     * @throws {InternalErrorResponse} error when the service returns a 500 status code
     */
     get(requestConfiguration?: RequestConfiguration<object> | undefined) : Promise<ImageDetail | undefined>;
    /**
     * Retrieves the details of an image using its ID
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {RequestInformation}
     */
     toGetRequestInformation(requestConfiguration?: RequestConfiguration<object> | undefined) : RequestInformation;
}
/**
 * Uri template for the request builder.
 */
export const ImagesItemRequestBuilderUriTemplate = "{+baseurl}/api/images/{id}";
/**
 * Metadata for all the requests in the request builder.
 */
export const ImagesItemRequestBuilderRequestsMetadata: RequestsMetadata = {
    get: {
        uriTemplate: ImagesItemRequestBuilderUriTemplate,
        responseBodyContentType: "application/json",
        errorMappings: {
            400: createProblemDetailsFromDiscriminatorValue as ParsableFactory<Parsable>,
            404: createErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
            500: createInternalErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
        },
        adapterMethodName: "send",
        responseBodyFactory:  createImageDetailFromDiscriminatorValue,
    },
};
/* tslint:enable */
/* eslint-enable */
