import { AccessTokenProvider, AllowedHostsValidator } from '@microsoft/kiota-abstractions';
import { useAuthStore } from '@/stores/authStore';
import { BaseBearerTokenAuthenticationProvider } from '@microsoft/kiota-abstractions';
import { FetchRequestAdapter } from '@microsoft/kiota-http-fetchlibrary';
import { createApiClient } from '@/api-client/apiClient';

export class ApiClientFactory {
    private static instance: ApiClientFactory;
    private static client: ReturnType<typeof createApiClient>;
    private authStore = useAuthStore();
    
    private constructor() {}
    
    static getInstance(): ApiClientFactory {
        if (!ApiClientFactory.instance) {
            ApiClientFactory.instance = new ApiClientFactory();
        }
        return ApiClientFactory.instance;
    }
    
    getClient(): ReturnType<typeof createApiClient> {
        if (!ApiClientFactory.client) {
            const accessTokenProvider = new AuthStoreAccessTokenProvider(this.authStore);
            const authProvider = new BaseBearerTokenAuthenticationProvider(accessTokenProvider);
            const adapter = new FetchRequestAdapter(authProvider);
            adapter.baseUrl = process.env.VITE_MOMENTUM_API_BASE_URL || '';
            ApiClientFactory.client = createApiClient(adapter);
        }
        return ApiClientFactory.client;
    }
}

class AuthStoreAccessTokenProvider implements AccessTokenProvider {
    private authStore: ReturnType<typeof useAuthStore>;

    constructor(authStore: ReturnType<typeof useAuthStore>) {
        this.authStore = authStore;
    }

    async getAuthorizationToken(url?: string, additionalAuthenticationContext?: Record<string, unknown>): Promise<string> {
        // Use the getAuthorizationToken method of authStore to get the token
        return  (await this.authStore).getAuthorizationToken();
    }

    getAllowedHostsValidator(): AllowedHostsValidator {
        // Return a validator that allows all hosts since we're handling auth elsewhere
        const validator = new AllowedHostsValidator();
        validator.setAllowedHosts(new Set(['*']));
        return validator;
    }
}
