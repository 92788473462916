/* tslint:disable */
/* eslint-disable */
// Generated by Microsoft Kiota
// @ts-ignore
import { createErrorResponseFromDiscriminatorValue, createInternalErrorResponseFromDiscriminatorValue, serializeUploadManyImagesRequest, type ErrorResponse, type InternalErrorResponse, type UploadManyImagesRequest } from '../../../models/';
// @ts-ignore
import { type BaseRequestBuilder, type Parsable, type ParsableFactory, type RequestConfiguration, type RequestInformation, type RequestsMetadata } from '@microsoft/kiota-abstractions';

/**
 * Builds and executes requests for operations under /api/images/from-remote
 */
export interface FromRemoteRequestBuilder extends BaseRequestBuilder<FromRemoteRequestBuilder> {
    /**
     * Process nd import a list of images with their URLs and optionally associate them with products using SKUs
     * @param body The request body
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @throws {ErrorResponse} error when the service returns a 400 status code
     * @throws {InternalErrorResponse} error when the service returns a 500 status code
     */
     post(body: UploadManyImagesRequest, requestConfiguration?: RequestConfiguration<object> | undefined) : Promise<void>;
    /**
     * Process nd import a list of images with their URLs and optionally associate them with products using SKUs
     * @param body The request body
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {RequestInformation}
     */
     toPostRequestInformation(body: UploadManyImagesRequest, requestConfiguration?: RequestConfiguration<object> | undefined) : RequestInformation;
}
/**
 * Uri template for the request builder.
 */
export const FromRemoteRequestBuilderUriTemplate = "{+baseurl}/api/images/from-remote";
/**
 * Metadata for all the requests in the request builder.
 */
export const FromRemoteRequestBuilderRequestsMetadata: RequestsMetadata = {
    post: {
        uriTemplate: FromRemoteRequestBuilderUriTemplate,
        responseBodyContentType: "application/problem+json",
        errorMappings: {
            400: createErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
            500: createInternalErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
        },
        adapterMethodName: "sendNoResponseContent",
        requestBodyContentType: "application/json",
        requestBodySerializer: serializeUploadManyImagesRequest,
        requestInformationContentSetMethod: "setContentFromParsable",
    },
};
/* tslint:enable */
/* eslint-enable */
