/* tslint:disable */
/* eslint-disable */
// Generated by Microsoft Kiota
// @ts-ignore
import { AssociateCustomerRequestBuilderRequestsMetadata, type AssociateCustomerRequestBuilder } from './associateCustomer/';
// @ts-ignore
import { AssociationsRequestBuilderRequestsMetadata, type AssociationsRequestBuilder } from './associations/';
// @ts-ignore
import { type BaseRequestBuilder, type KeysToExcludeForNavigationMetadata, type NavigationMetadata } from '@microsoft/kiota-abstractions';

/**
 * Builds and executes requests for operations under /api/organizations/users
 */
export interface UsersRequestBuilder extends BaseRequestBuilder<UsersRequestBuilder> {
    /**
     * The associateCustomer property
     */
    get associateCustomer(): AssociateCustomerRequestBuilder;
    /**
     * The associations property
     */
    get associations(): AssociationsRequestBuilder;
}
/**
 * Uri template for the request builder.
 */
export const UsersRequestBuilderUriTemplate = "{+baseurl}/api/organizations/users";
/**
 * Metadata for all the navigation properties in the request builder.
 */
export const UsersRequestBuilderNavigationMetadata: Record<Exclude<keyof UsersRequestBuilder, KeysToExcludeForNavigationMetadata>, NavigationMetadata> = {
    associateCustomer: {
        requestsMetadata: AssociateCustomerRequestBuilderRequestsMetadata,
    },
    associations: {
        requestsMetadata: AssociationsRequestBuilderRequestsMetadata,
    },
};
/* tslint:enable */
/* eslint-enable */
