/* tslint:disable */
/* eslint-disable */
// Generated by Microsoft Kiota
// @ts-ignore
import { createApiAuthenticationResponseFromDiscriminatorValue, createErrorResponseFromDiscriminatorValue, createInternalErrorResponseFromDiscriminatorValue, serializeApiAuthenticationRequest, serializeApiAuthenticationResponse, type ApiAuthenticationRequest, type ApiAuthenticationResponse, type ErrorResponse, type InternalErrorResponse } from '../../../models/';
// @ts-ignore
import { type BaseRequestBuilder, type Parsable, type ParsableFactory, type RequestConfiguration, type RequestInformation, type RequestsMetadata } from '@microsoft/kiota-abstractions';

/**
 * Builds and executes requests for operations under /api/auth/login
 */
export interface LoginRequestBuilder extends BaseRequestBuilder<LoginRequestBuilder> {
    /**
     * This endpoint is responsible for user authentication. It returns an access token in thr form of a JWT to be used for subsequent requests.
     * @param body Represents a request for API authentication.
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {Promise<ApiAuthenticationResponse>}
     * @throws {ErrorResponse} error when the service returns a 400 status code
     * @throws {InternalErrorResponse} error when the service returns a 500 status code
     */
     post(body: ApiAuthenticationRequest, requestConfiguration?: RequestConfiguration<object> | undefined) : Promise<ApiAuthenticationResponse | undefined>;
    /**
     * This endpoint is responsible for user authentication. It returns an access token in thr form of a JWT to be used for subsequent requests.
     * @param body Represents a request for API authentication.
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {RequestInformation}
     */
     toPostRequestInformation(body: ApiAuthenticationRequest, requestConfiguration?: RequestConfiguration<object> | undefined) : RequestInformation;
}
/**
 * Uri template for the request builder.
 */
export const LoginRequestBuilderUriTemplate = "{+baseurl}/api/auth/login";
/**
 * Metadata for all the requests in the request builder.
 */
export const LoginRequestBuilderRequestsMetadata: RequestsMetadata = {
    post: {
        uriTemplate: LoginRequestBuilderUriTemplate,
        responseBodyContentType: "application/json",
        errorMappings: {
            400: createErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
            500: createInternalErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
        },
        adapterMethodName: "send",
        responseBodyFactory:  createApiAuthenticationResponseFromDiscriminatorValue,
        requestBodyContentType: "application/json",
        requestBodySerializer: serializeApiAuthenticationRequest,
        requestInformationContentSetMethod: "setContentFromParsable",
    },
};
/* tslint:enable */
/* eslint-enable */
