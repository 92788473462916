<script setup lang="ts">

import { onMounted, ref } from 'vue';
import { storeToRefs } from 'pinia';

import { useAuthStore } from '@/stores/authStore';
import { useImageStore } from '@/stores/imageStore';
import { MultipartBody } from '@microsoft/kiota-abstractions';
const authStore = useAuthStore();
const imageStore = useImageStore();

const { currentOrg, logoUrl } = storeToRefs(authStore);
const { orgImageLoading } = storeToRefs(imageStore);

const file = ref<File | null>();
const form = ref<HTMLFormElement>();
const logoImageFail = ref(false);
const binaryString = ref<string>("");

onMounted(() => {
    authStore.getLogoUrl();
})

// const onUpload = () => {
//     // console.log('onUpload');
// }

const convertToBinary = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
            const arrayBuffer = reader.result as ArrayBuffer;
            const uint8Array = new Uint8Array(arrayBuffer);
            const binaryStr = String.fromCharCode(...uint8Array);
            resolve(binaryStr);
        };
        reader.onerror = reject;
        reader.readAsArrayBuffer(file);
    });
};

const toBase64 = (file: any) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
});

const onFileChanged = async (e: Event) => {
    const target = e.target as HTMLInputElement;
    if (target && target.files) {
        file.value = target.files[0];
        const formData = new FormData();
        formData.append("logo", file.value);
        try {
            await imageStore.createOrganizationImage(formData);
            authStore.getLogoUrl();
        } catch (error) {
            console.log(error);
        }
    } 
}

const setLogoImageFail = () => {
    logoImageFail.value = true;
}

</script>


<template>
    <div :class="$style.logoWrap">
        <div v-if="orgImageLoading" :class="$style.uploadBox">
            <ProgressSpinner :class="$style.spinner" />
        </div>
        <div v-else-if="logoUrl && !logoImageFail" :class="$style.imageWrap" class="group">
            <img :class="$style.logoImage" :src="logoUrl" :alt="currentOrg" @error="setLogoImageFail">
            <div :class="$style.imageWrapHover" class="group-hover:flex">
                <span>Change Logo</span>
                <input type="file" :class="$style.fileInput" ref="fileInput" @change="onFileChanged($event)"
                    accept="image/*" capture />
            </div>
        </div>
        <div v-else :class="$style.uploadBox">
            <i :class="$style.plusIcon" class="pi pi-plus"></i>
            <span :class="$style.addText">Add Logo</span>
            <input type="file" :class="$style.fileInput" ref="fileInput" @change="onFileChanged($event)"
                accept="image/*" capture />
        </div>
    </div>
</template>




<style module>
.logoWrap {
    @apply w-full flex flex-col justify-center;
}

.uploadBox {
    @apply relative border border-primary w-[200px] h-[100px] rounded-lg flex flex-col items-center justify-center cursor-pointer hover:border-2 hover:bg-primary/10;
}

.imageWrap {
    @apply relative border border-primary w-[200px] h-[100px] rounded-lg flex flex-col items-center justify-center overflow-hidden
}

.imageWrapHover {
    @apply hidden absolute bg-neutral-900/85 flex-col items-center justify-center w-full h-full text-neutral-100 text-base;
}

.logoImage {
    @apply max-w-[198px] max-h-[98px]
}

.plusIcon {
    @apply text-xl text-primary mb-1;
}

.addText {
    @apply font-semibold text-primary
}

.fileInput {
    @apply border border-lime-500 w-full h-full absolute opacity-0 cursor-pointer;
}
.spinner {
    @apply w-12;
}
</style>