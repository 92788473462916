/* tslint:disable */
/* eslint-disable */
// Generated by Microsoft Kiota
// @ts-ignore
import { createErrorResponseFromDiscriminatorValue, createInternalErrorResponseFromDiscriminatorValue, createPagedResultOfEntityDetailsResponseOfImageDetailFromDiscriminatorValue, serializeDeleteManyImagesRequest, type DeleteManyImagesRequest, type ErrorResponse, type InternalErrorResponse, type PagedResultOfEntityDetailsResponseOfImageDetail } from '../../models/';
// @ts-ignore
import { FromLocalRequestBuilderRequestsMetadata, type FromLocalRequestBuilder } from './fromLocal/';
// @ts-ignore
import { FromRemoteRequestBuilderRequestsMetadata, type FromRemoteRequestBuilder } from './fromRemote/';
// @ts-ignore
import { ImagesItemRequestBuilderRequestsMetadata, type ImagesItemRequestBuilder } from './item/';
// @ts-ignore
import { OrganizationLogoRequestBuilderRequestsMetadata, type OrganizationLogoRequestBuilder } from './organizationLogo/';
// @ts-ignore
import { type BaseRequestBuilder, type KeysToExcludeForNavigationMetadata, type NavigationMetadata, type Parsable, type ParsableFactory, type RequestConfiguration, type RequestInformation, type RequestsMetadata } from '@microsoft/kiota-abstractions';

/**
 * Builds and executes requests for operations under /api/images
 */
export interface ImagesRequestBuilder extends BaseRequestBuilder<ImagesRequestBuilder> {
    /**
     * The fromLocal property
     */
    get fromLocal(): FromLocalRequestBuilder;
    /**
     * The fromRemote property
     */
    get fromRemote(): FromRemoteRequestBuilder;
    /**
     * The organizationLogo property
     */
    get organizationLogo(): OrganizationLogoRequestBuilder;
    /**
     * Gets an item from the MomentumAPI.api.images.item collection
     * @param id Unique identifier of the item
     * @returns {ImagesItemRequestBuilder}
     */
     byId(id: string) : ImagesItemRequestBuilder;
    /**
     * Remove multiple images from the system using their unique identifiers
     * @param body The request body
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @throws {ErrorResponse} error when the service returns a 400 status code
     * @throws {InternalErrorResponse} error when the service returns a 500 status code
     */
     delete(body: DeleteManyImagesRequest, requestConfiguration?: RequestConfiguration<object> | undefined) : Promise<void>;
    /**
     * Retrieves a paginated list of images with optional filtering by name or product SKU
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {Promise<PagedResultOfEntityDetailsResponseOfImageDetail>}
     * @throws {ErrorResponse} error when the service returns a 400 status code
     * @throws {InternalErrorResponse} error when the service returns a 500 status code
     */
     get(requestConfiguration?: RequestConfiguration<ImagesRequestBuilderGetQueryParameters> | undefined) : Promise<PagedResultOfEntityDetailsResponseOfImageDetail | undefined>;
    /**
     * Remove multiple images from the system using their unique identifiers
     * @param body The request body
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {RequestInformation}
     */
     toDeleteRequestInformation(body: DeleteManyImagesRequest, requestConfiguration?: RequestConfiguration<object> | undefined) : RequestInformation;
    /**
     * Retrieves a paginated list of images with optional filtering by name or product SKU
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {RequestInformation}
     */
     toGetRequestInformation(requestConfiguration?: RequestConfiguration<ImagesRequestBuilderGetQueryParameters> | undefined) : RequestInformation;
}
/**
 * Retrieves a paginated list of images with optional filtering by name or product SKU
 */
export interface ImagesRequestBuilderGetQueryParameters {
    /**
     * Gets or sets the filters for the request, this is optionalAcceptable operators are: starts with, contains, not contains, ends with, equals, not equals
     */
    filters?: string[];
    /**
     * Gets or sets the page number for the request. Default value is 1.
     */
    pageNumber?: number;
    /**
     * Gets or sets the page size for the request. Default value is 25.
     */
    pageSize?: number;
    /**
     * Gets or sets the sort direction. Can be "ASC" or "DESC". Default value is "asc".
     */
    sortDirection?: string;
    sortOn?: string;
}
/**
 * Uri template for the request builder.
 */
export const ImagesRequestBuilderUriTemplate = "{+baseurl}/api/images?pageNumber={pageNumber}&pageSize={pageSize}&sortDirection={sortDirection}&sortOn={sortOn}{&filters*}";
/**
 * Metadata for all the navigation properties in the request builder.
 */
export const ImagesRequestBuilderNavigationMetadata: Record<Exclude<keyof ImagesRequestBuilder, KeysToExcludeForNavigationMetadata>, NavigationMetadata> = {
    byId: {
        requestsMetadata: ImagesItemRequestBuilderRequestsMetadata,
        pathParametersMappings: ["id"],
    },
    fromLocal: {
        requestsMetadata: FromLocalRequestBuilderRequestsMetadata,
    },
    fromRemote: {
        requestsMetadata: FromRemoteRequestBuilderRequestsMetadata,
    },
    organizationLogo: {
        requestsMetadata: OrganizationLogoRequestBuilderRequestsMetadata,
    },
};
/**
 * Metadata for all the requests in the request builder.
 */
export const ImagesRequestBuilderRequestsMetadata: RequestsMetadata = {
    delete: {
        uriTemplate: ImagesRequestBuilderUriTemplate,
        responseBodyContentType: "application/problem+json",
        errorMappings: {
            400: createErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
            500: createInternalErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
        },
        adapterMethodName: "sendNoResponseContent",
        requestBodyContentType: "application/json",
        requestBodySerializer: serializeDeleteManyImagesRequest,
        requestInformationContentSetMethod: "setContentFromParsable",
    },
    get: {
        uriTemplate: ImagesRequestBuilderUriTemplate,
        responseBodyContentType: "application/json",
        errorMappings: {
            400: createErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
            500: createInternalErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
        },
        adapterMethodName: "send",
        responseBodyFactory:  createPagedResultOfEntityDetailsResponseOfImageDetailFromDiscriminatorValue,
    },
};
/* tslint:enable */
/* eslint-enable */
