<script setup lang="ts">
import { ref } from 'vue';
import { useLayout } from './composables/layout';
import AppSubMenu from './AppSubMenu.vue';

const {layoutConfig} = useLayout();

const model = ref([
    {
        items: [
            {
                label: 'Dashboard',
                icon: 'pi pi-fw pi-home',
                to: '/'
            },
            {
                label: 'Customers',
                icon: 'pi pi-fw pi-users',
                to: '/customers'
            },
            {
                label: 'Products',
                icon: 'pi pi-fw pi-list',
                to: '/products'
            },
            {
                label: 'Images',
                icon: 'pi pi-fw pi-image',
                to: '/images'
            },
            {
                label: 'Carts',
                icon: 'pi pi-fw pi-shopping-bag',
                to: '/carts'
            },
            {
                label: 'Orders',
                icon: 'pi pi-fw pi-shopping-cart',
                to: '/orders'
            },
            {
                label: 'Sales Reps',
                icon: 'pi pi-fw pi-user',
                to: '/sales-reps'
            },
            {
                label: 'Access Requests',
                icon: 'pi pi-fw pi-key',
                to: '/access-requests'
            },
            {
                label: 'Quickbooks',
                icon: 'pi pi-fw pi-book',
                to: '/quickbooks'
            }
        ]
    },
]);

const slimModel = ref([

    {
        label: 'Dashboard',
        icon: 'pi pi-fw pi-home',
        to: '/'
    },
    {
        label: 'Customers',
        icon: 'pi pi-fw pi-users',
        to: '/customers'
    },
    {
        label: 'Products',
        icon: 'pi pi-fw pi-list',
        to: '/products'
    },
    {
        label: 'Carts',
        icon: 'pi pi-fw pi-shopping-bag',
        to: '/carts'
    },
    {
        label: 'Orders',
        icon: 'pi pi-fw pi-shopping-cart',
        to: '/orders'
    },
    {
        label: 'Sales Reps',
        icon: 'pi pi-fw pi-user',
        to: '/sales-reps'
    },
    {
        label: 'Access Requests',
        icon: 'pi pi-fw pi-key',
        to: '/access-requests'
    },
    {
        label: 'Quickbooks',
        icon: 'pi pi-fw pi-book',
        to: '/quickbooks'
    }

]);


</script>

<template>
    <AppSubMenu :model="layoutConfig.menuMode === 'slim' ? slimModel : model" />
</template>

<style lang="scss" scoped></style>
