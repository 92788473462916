/* tslint:disable */
/* eslint-disable */
// Generated by Microsoft Kiota
// @ts-ignore
import { createProblemDetailsFromDiscriminatorValue, createUpdateCustomerResponseFromDiscriminatorValue, serializeUpdateCustomerRequest2, serializeUpdateCustomerResponse, type ProblemDetails, type UpdateCustomerRequest2, type UpdateCustomerResponse } from '../../../models/';
// @ts-ignore
import { type BaseRequestBuilder, type Parsable, type ParsableFactory, type RequestConfiguration, type RequestInformation, type RequestsMetadata } from '@microsoft/kiota-abstractions';

/**
 * Builds and executes requests for operations under /api/customers/update
 */
export interface UpdateRequestBuilder extends BaseRequestBuilder<UpdateRequestBuilder> {
    /**
     * @param body The request body
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {Promise<UpdateCustomerResponse>}
     * @throws {ProblemDetails} error when the service returns a 400 status code
     */
     post(body: UpdateCustomerRequest2, requestConfiguration?: RequestConfiguration<object> | undefined) : Promise<UpdateCustomerResponse | undefined>;
    /**
     * @param body The request body
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {RequestInformation}
     */
     toPostRequestInformation(body: UpdateCustomerRequest2, requestConfiguration?: RequestConfiguration<object> | undefined) : RequestInformation;
}
/**
 * Uri template for the request builder.
 */
export const UpdateRequestBuilderUriTemplate = "{+baseurl}/api/customers/update";
/**
 * Metadata for all the requests in the request builder.
 */
export const UpdateRequestBuilderRequestsMetadata: RequestsMetadata = {
    post: {
        uriTemplate: UpdateRequestBuilderUriTemplate,
        responseBodyContentType: "application/json",
        errorMappings: {
            400: createProblemDetailsFromDiscriminatorValue as ParsableFactory<Parsable>,
        },
        adapterMethodName: "send",
        responseBodyFactory:  createUpdateCustomerResponseFromDiscriminatorValue,
        requestBodyContentType: "application/json",
        requestBodySerializer: serializeUpdateCustomerRequest2,
        requestInformationContentSetMethod: "setContentFromParsable",
    },
};
/* tslint:enable */
/* eslint-enable */
