/* tslint:disable */
/* eslint-disable */
// Generated by Microsoft Kiota
// @ts-ignore
import { type WithCustomerNumberItemRequestBuilder, WithCustomerNumberItemRequestBuilderRequestsMetadata } from './item/';
// @ts-ignore
import { type BaseRequestBuilder, type KeysToExcludeForNavigationMetadata, type NavigationMetadata } from '@microsoft/kiota-abstractions';

/**
 * Builds and executes requests for operations under /api/carts/by-customer
 */
export interface ByCustomerRequestBuilder extends BaseRequestBuilder<ByCustomerRequestBuilder> {
    /**
     * Gets an item from the MomentumAPI.api.carts.byCustomer.item collection
     * @param customerNumber Unique identifier of the item
     * @returns {WithCustomerNumberItemRequestBuilder}
     */
     byCustomerNumber(customerNumber: string) : WithCustomerNumberItemRequestBuilder;
}
/**
 * Uri template for the request builder.
 */
export const ByCustomerRequestBuilderUriTemplate = "{+baseurl}/api/carts/by-customer";
/**
 * Metadata for all the navigation properties in the request builder.
 */
export const ByCustomerRequestBuilderNavigationMetadata: Record<Exclude<keyof ByCustomerRequestBuilder, KeysToExcludeForNavigationMetadata>, NavigationMetadata> = {
    byCustomerNumber: {
        requestsMetadata: WithCustomerNumberItemRequestBuilderRequestsMetadata,
        pathParametersMappings: ["customerNumber"],
    },
};
/* tslint:enable */
/* eslint-enable */
