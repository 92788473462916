<script setup lang="ts">
import {useLayout } from '@/layout/composables/layout';
const {isSlim, toggleMenu } = useLayout();

</script>
<template>
    <!-- Template Class for reference class="sidebar-header" -->
   
        <div :class="`${$style.header} ${isSlim ? $style.slim: ''}` ">
            <div :class="`${$style.logoWrap} ${isSlim ? 'group' : ''}`">
                <img :class="`${$style.logo} group-hover:hidden opacity-100 group-hover:opacity-0 transition-opacity`" src="@/assets/logo/momentum-logo-sm.png" alt="Logo" @click="toggleMenu" />
                <Button class="hidden group-hover:flex w-10 group-hover:border-slate-300 group-hover:bg-white opacity-0 hover:opacity-100 transition-opacity duration-500" icon="pi pi-bars" rounded variant="text" severity="secondary" aria-label="Filter" @click="toggleMenu" />
            </div>
            <ul v-if="!isSlim" class="flex items-center justify-end">
                <li class="text-sm text-gray-500">
                    <Button icon="pi pi-bars" class="hover:border-slate-300 hover:bg-white" rounded variant="text" severity="secondary" aria-label="Filter" @click="toggleMenu" />
                </li>
            </ul >
         
        </div>

</template>
<style module>  
.logo{
    @apply w-10
}
.header{
    @apply flex w-full items-center justify-between pl-8 h-8 pr-2 lg:pr-0;
    &.slim{
        @apply justify-center pl-0;
    }
}


</style>
