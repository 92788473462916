/* tslint:disable */
/* eslint-disable */
// Generated by Microsoft Kiota
// @ts-ignore
import { createCheckAccessRequestExistsResponseFromDiscriminatorValue, createErrorResponseFromDiscriminatorValue, createInternalErrorResponseFromDiscriminatorValue, type CheckAccessRequestExistsResponse, type ErrorResponse, type InternalErrorResponse } from '../../../../models/';
// @ts-ignore
import { type BaseRequestBuilder, type Parsable, type ParsableFactory, type RequestConfiguration, type RequestInformation, type RequestsMetadata } from '@microsoft/kiota-abstractions';

/**
 * Builds and executes requests for operations under /api/organizations/access-requests/exists
 */
export interface ExistsRequestBuilder extends BaseRequestBuilder<ExistsRequestBuilder> {
    /**
     * Checks if an access request exists for the given email address in the organization
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {Promise<CheckAccessRequestExistsResponse>}
     * @throws {ErrorResponse} error when the service returns a 400 status code
     * @throws {InternalErrorResponse} error when the service returns a 500 status code
     */
     get(requestConfiguration?: RequestConfiguration<ExistsRequestBuilderGetQueryParameters> | undefined) : Promise<CheckAccessRequestExistsResponse | undefined>;
    /**
     * Checks if an access request exists for the given email address in the organization
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {RequestInformation}
     */
     toGetRequestInformation(requestConfiguration?: RequestConfiguration<ExistsRequestBuilderGetQueryParameters> | undefined) : RequestInformation;
}
/**
 * Checks if an access request exists for the given email address in the organization
 */
export interface ExistsRequestBuilderGetQueryParameters {
    /**
     * The email address to check for an existing access request
     */
    emailAddress?: string;
}
/**
 * Uri template for the request builder.
 */
export const ExistsRequestBuilderUriTemplate = "{+baseurl}/api/organizations/access-requests/exists?emailAddress={emailAddress}";
/**
 * Metadata for all the requests in the request builder.
 */
export const ExistsRequestBuilderRequestsMetadata: RequestsMetadata = {
    get: {
        uriTemplate: ExistsRequestBuilderUriTemplate,
        responseBodyContentType: "application/json",
        errorMappings: {
            400: createErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
            500: createInternalErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
        },
        adapterMethodName: "send",
        responseBodyFactory:  createCheckAccessRequestExistsResponseFromDiscriminatorValue,
    },
};
/* tslint:enable */
/* eslint-enable */
