import { ref, computed, UnwrapRef, Ref } from 'vue';

import { defineStore } from 'pinia';
import { getKindeClient, getTokenWithRefresh } from '@/utils/kindeAuth';
import { GetOrganizationResponse, ListUserOrganizationsResponse, Organization } from '@/api-client/models';
import { ApiClientFactory } from '@/stores/apiClientFactory';
import { saveCurrentOrg, getCurrentOrg } from '@/stores/localStorageService';
import { handleGlobalError } from '@/composables/globalErrorHandler';

export const useAuthStore = defineStore('auth', () => {
  // State
  const isAuthenticated = ref(false);
  const user = ref<any | null>(null);

  const organizations = ref<Array<Organization>>([]);
  const organization = ref<Organization>({});
  const roles = ref<Array<string>>([]);
  const admin = ref(false);
  const accessToken: Ref<UnwrapRef<string>> = ref('');
  const currentOrgId = ref('');
  const currentOrg = ref('');
  const priceTiers = <any>ref(null);
  const tiersLoading = ref(false);

  const clientFactory = ApiClientFactory.getInstance();
  const client = clientFactory.getClient();

  const logoUrl = ref('')




  // Actions
  const checkAuth = async () => {
    try {
      const kindeClient = getKindeClient();

      // Check if auth data exists in localStorage
      const storedUser = localStorage.getItem('auth_user');
      const storedToken = localStorage.getItem('auth_token');
      const storedOrgId = localStorage.getItem('auth_org_id');
      const storedOrg = localStorage.getItem('auth_org');
      const storedAdmin = localStorage.getItem('auth_admin');

      if (storedUser && storedToken && storedUser !== 'undefined' && storedToken !== 'undefined') {
        user.value = JSON.parse(storedUser);
        accessToken.value = storedToken;
        currentOrgId.value = storedOrgId || '';
        currentOrg.value = storedOrg || '';
        admin.value = storedAdmin === 'true';
        isAuthenticated.value = true;
        return;
      }

      // Fetch user information if not in localStorage
      const fetchedUser = await kindeClient.getUser();
      user.value = fetchedUser;
      isAuthenticated.value = !!fetchedUser;

      accessToken.value = <string>await getTokenWithRefresh();
      currentOrgId.value = <string>kindeClient.getClaim("org_code")?.value || '';
      currentOrg.value = <string>kindeClient.getClaim("org_name")?.value || '';
      const roles: any = kindeClient.getClaim("roles")?.value;

      // console.log('user.value');
      // console.log(user.value);
      // console.log('currentOrgId.value');
      // console.log(currentOrgId.value);



      admin.value = roles?.some((role: any) => role.key === 'admin') || false;

      // Persist auth data to localStorage
      localStorage.setItem('auth_user', JSON.stringify(fetchedUser));
      localStorage.setItem('auth_token', accessToken.value);
      localStorage.setItem('auth_org_id', currentOrgId.value);
      localStorage.setItem('auth_org', currentOrg.value);
      localStorage.setItem('auth_admin', admin.value.toString());

    } catch (error) {
      console.error('Authentication error:', error);
      clearAuthData();
    }
  };

  const login = async () => {
    const kindeClient = getKindeClient();
    // Start the login flow (correct method for PKCE SDK)
    await kindeClient.login({
      app_state: {
        redirectTo: window.location
      }
    }); // Correct method
  };

  const logout = async () => {
    const kindeClient = getKindeClient();
    // Logout and clear the user session
    await kindeClient.logout();
    clearAuthData();
  };

  
  const clearAuthData = () => {
    isAuthenticated.value = false;
    user.value = null;
    localStorage.removeItem('auth_user');
    localStorage.removeItem('auth_token');
    localStorage.removeItem('auth_org_id');
    localStorage.removeItem('auth_org');
    localStorage.removeItem('auth_admin');
  }
    
  // Handle the redirect callback after login
  const handleRedirectCallback = async () => {
    try {
      const kindeClient = getKindeClient();
      // Parse the redirect to extract the user data and tokens
      const response = await kindeClient.handleRedirectCallback();
      if (response.user) {
        user.value = response.user;
        isAuthenticated.value = true;
      }
    } catch (error) {
      console.error('Error handling redirect callback:', error);
    }
  };

  // Getters
  const getUser = computed(() => user.value);

  const getAuthorizationToken = async () => {
    try {
      // Get fresh token with automatic refresh if needed
      const token = await getTokenWithRefresh();
      if (token) {
        accessToken.value = token;
      }
      return accessToken.value;
    } catch (error) {
      console.error('Error getting authorization token:', error);
      // If token refresh fails, trigger login flow
      if (!accessToken.value) {
        clearAuthData();
        await login();
      }
      return accessToken.value;
    }
  };

  const getPortalSessionLink = async () => {
    try {
      const response = await client.api.organizations.subscriptions.portalSessionLink.get();
      return response;
    } catch (error: any) {
      handleGlobalError(error);
    }
  };

  const getReadmeLoginUrl = async () => {
    try {
      const response = await client.api.auth.readme.get();
      return response;
    } catch (error: any) {
      handleGlobalError(error);
    }
};

const changeOrganization = (org_id: string) => {
  //TODO: verify this 
  saveCurrentOrg(org_id);
  currentOrgId.value = org_id;
  getOrganization();
};

const getOrganization = async () => {
  try {
      const response = await client.api.organization.get();
      organization.value = response?.organization ?? {};
      return organization.value;
    } catch (error: any) {
      handleGlobalError(error);
    }
  };


const getLogoUrl = async () => {
  try {
      const response = await client.api.organization.get();
      logoUrl.value = response?.organization?.logoUrl ?? '';
      organization.value = response?.organization ?? {};

    } catch (error: any) {
      handleGlobalError(error);
    }
  };




  const getOrganizations = async () => {
    try {
      const response: ListUserOrganizationsResponse | undefined = await client.api.users.organizations.get();
      organizations.value = response?.organizations ?? [];
      console.log('getOrganizations:', response);

      currentOrgId.value = organizations.value[0].identityOrganizationId ?? '';
      saveCurrentOrg(currentOrgId.value);
    } catch (error: any) {
      handleGlobalError(error);
    }
  };

  const getPriceTiers = async () => {
    tiersLoading.value = true;
    try {
      const response = await client.api.organization.get();
      priceTiers.value = response?.organization?.priceTiers;
    } catch (error: any) {
      handleGlobalError(error);
    } finally {
      tiersLoading.value = false;
    }
  };

  return {
    isAuthenticated,
    user,
    admin,
    checkAuth,
    login,
    logout,
    handleRedirectCallback,
    getUser,
    getAuthorizationToken,
    getPortalSessionLink,
    getReadmeLoginUrl,
    changeOrganization,
    getLogoUrl,
    getPriceTiers,
    currentOrg,
    currentOrgId,
    priceTiers,
    tiersLoading,
    logoUrl,
    getOrganization,
    organization
  };
});
