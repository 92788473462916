<script setup lang="ts">
import { ref, watch } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute();
const breadcrumbRoutes = ref<any[]>([]);

function setBreadcrumbRoutes() {
    if (route.meta.breadcrumb) {
        const breadcrumb = route.meta.breadcrumb;
        if (typeof breadcrumb === 'function') {
            breadcrumbRoutes.value = breadcrumb(route) as string[];
        } else {
            breadcrumbRoutes.value = breadcrumb as any[];
        }
        return;
    }
    breadcrumbRoutes.value = route.fullPath
        .split('/')
        .filter((item) => item !== '')
        .filter((item) => isNaN(Number(item)))
        .map((item) => item.charAt(0).toUpperCase() + item.slice(1));
}

watch(
    route,
    () => {
        setBreadcrumbRoutes();
    },
    { immediate: true }
);
</script>

<template>
    <nav class="layout-breadcrumb">
        <ol>
            <template v-for="(breadcrumbRoute, i) in breadcrumbRoutes" :key="breadcrumbRoute">
                <router-link v-if="breadcrumbRoutes.length > 1 && i !== breadcrumbRoutes.length - 1" :to="breadcrumbRoute.path">
                    <li>{{ breadcrumbRoute.name }}</li>
                </router-link>
                <li v-else>{{ breadcrumbRoute.name }}</li>
                <li v-if="i !== breadcrumbRoutes.length - 1" class="layout-breadcrumb-chevron">/</li>
            </template>
        </ol>
    </nav>
</template>
